import { Component, ElementRef, HostListener, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { AllRequestParams, StopWordsService } from "../../shared/services/stop-words.service";
import { ModalService } from "../../shared/services/modal.service";
import { trigger, state, style, transition, animate } from "@angular/animations";
import { LIVE_NUMBER_TEST_STATUSES, MESSAGE_STATES } from "../../shared/models/test-group.model";
import { CustomUtils } from "../../shared/services/custom-utils";
import { UsersService } from "../../shared/services/users.service";
import { AuthUser } from "../../shared/models/user.model";

declare const fcWidget: any;

@Component({
    selector: 'app-info',
    templateUrl: './info.component.html',
    styleUrls: ['./info.component.scss'],
    animations: [
        trigger('openClose', [
            state('open', style({
                opacity: 1,
                transform: 'translateY(0)'
            })),
            state('closed', style({
                opacity: 0,
                transform: 'translateY(100%)'
            })),
            transition('void => *', [
                style({ opacity: 0, transform: 'translateY(100%)' }),
                animate('0.3s')
            ]),
            transition('* => void', [
                animate('0.2s', style({
                    opacity: 0,
                    transform: 'translateY(100%)'
                }))
            ])
        ]),
    ]
})

export class InfoComponent implements OnInit {

    currentInfo: string = '';
    currentLinkForDocumentation: string = '';
    currentNavigationPath: string = '';
    showFabContent: boolean = false;
    dlrStatuses: { id: string, label: string }[] = [];
    receiptStatuses: { id: string, label: string }[] = [];
    stopWords: string[] = [];
    currentUser: AuthUser;
    showFabButton: boolean = true;

    urls: InfoUrls = {
        liveTestingModalTpl: ['/live-testing'],
        manualTestingModalTpl: ['/manual-testing'],
        mobileOriginatedModalTpl: ['/mo-testing'],
        cliTestingModalTpl: ['/cli-testing'],
        sessionsModalTpl: ['/sessions', '/sessions/create'],
    };

    pathsToShowDocumentation: string[] = [
        '/dashboard',
        '/live-testing',
        '/manual-testing',
        '/cli-testing',
        '/scheduler/tasks',
        '/scheduler/reports',
        '/suppliers',
        '/sessions',
        '/sender-templates', '/text-templates', '/test-case-templates', '/content-templates',
        '/user/account', '/user/list',
        '/user/account/api',
        '/pricing-general', '/pricing-special', '/billing/postpaid', '/billing/prepaid', '/invoices',
        '/audit/logs'
    ]

    modalUrlMap: any = {
        '/dashboard': 'https://help.telqtele.com/tp/dashboard',
        '/live-testing': 'https://help.telqtele.com/tp/live-number-testing',
        '/manual-testing': 'https://help.telqtele.com/tp/manual-testing',
        '/cli-testing': 'https://help.telqtele.com/tp/cli-testing',
        '/scheduler/tasks': 'https://help.telqtele.com/tp/Scheduler.1657962529.html',
        '/scheduler/reports': 'https://help.telqtele.com/tp/Report.1705312436.html',
        '/suppliers': 'https://help.telqtele.com/tp/Suppliers.1658159112.html',
        '/sessions': 'https://help.telqtele.com/tp/smpp-connections',
        '/sender-templates': 'https://help.telqtele.com/tp/Templates.1657372676.html',
        '/text-templates': 'https://help.telqtele.com/tp/Templates.1657372676.html',
        '/test-case-templates': 'https://help.telqtele.com/tp/Templates.1657372676.html',
        '/content-templates': 'https://help.telqtele.com/tp/Templates.1657372676.html',
        '/user/account': 'https://help.telqtele.com/tp/Account-settings.1657438253.html',
        '/user/list': 'https://help.telqtele.com/tp/Account-settings.1657438253.html',
        '/user/account/api': 'https://help.telqtele.com/tp/API---REST-API-setup.1658093569.html',
        '/pricing-general': 'https://help.telqtele.com/tp/Prices.2870738945.html',
        '/pricing-special': 'https://help.telqtele.com/tp/Prices.2870738945.html',
        '/billing/postpaid': 'https://help.telqtele.com/tp/Prices.2870738945.html',
        '/billing/prepaid': 'https://help.telqtele.com/tp/Prices.2870738945.html',
        '/invoices': 'https://help.telqtele.com/tp/Invoices.1657569287.html',
        '/audit/logs': 'https://help.telqtele.com/tp/Logs.1657405596.html'
    };

    @ViewChild('sessionsModalTpl', { read: TemplateRef, static: false }) sessionsModalTpl: any;
    @ViewChild('liveTestingModalTpl', { read: TemplateRef, static: false }) liveTestingModalTpl: any;
    @ViewChild('manualTestingModalTpl', { read: TemplateRef, static: false }) manualTestingModalTpl: any;
    @ViewChild('cliTestingModalTpl', { read: TemplateRef, static: false }) cliTestingModalTpl: any;
    @ViewChild('mobileOriginatedModalTpl', { read: TemplateRef, static: false }) mobileOriginatedModalTpl: any;
    @ViewChild('pricingModalTpl', { read: TemplateRef, static: false }) pricingModalTpl: any;
    @ViewChild('invoiceModalTpl', { read: TemplateRef, static: false }) invoiceModalTpl: any;
    @ViewChild('generalModalTpl', { read: TemplateRef, static: false }) generalModalTpl: any;

    modals = [
        'sessionsModalTpl',
        'liveTestingModalTpl',
        'manualTestingModalTpl',
        'cliTestingModalTpl',
        'mobileOriginatedModalTpl',
        'pricingModalTpl',
        'invoiceModalTpl',
        'generalModalTpl'
    ];

    hideFabButtonsOnRoutes: string[] = [
        '/users',
    ];

    constructor(
        public stopWordsService: StopWordsService,
        public router: Router,
        public modal: ModalService,
        private elementRef: ElementRef,
        private userService: UsersService,
    ) { }

    @HostListener('document:click', ['$event'])
    clickout(event) {
        if (!this.elementRef.nativeElement.contains(event.target)) {
            this.showFabContent = false;
        }
    }

    @HostListener("window:scroll", [])
    onWindowScroll() {
        const yOffset = window.scrollY;
        const windowHeight = window.innerHeight;
        const bodyHeight = document.body.offsetHeight - 100;

        // Check if user has scrolled to bottom
        if (yOffset + windowHeight >= bodyHeight) {
            this.showFabButton = false;
        } else {
            this.showFabButton = true;
        }
    }

    ngOnInit() {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.currentInfo = this.getInfoByUrl(event.urlAfterRedirects);
                this.currentNavigationPath = event.urlAfterRedirects;
                this.currentLinkForDocumentation = this.modalUrlMap[event.urlAfterRedirects] || '#';
                const shouldFabBeHidden = this.hideFabButtonsOnRoutes.some(r => this.currentNavigationPath.includes(r));
                setTimeout(() => {
                    this.showFabButton = !shouldFabBeHidden;
                }, 100);
            }
        });
        MESSAGE_STATES.forEach(_ => this.dlrStatuses.push(_));
        LIVE_NUMBER_TEST_STATUSES.forEach(_ => this.receiptStatuses.push(_));
        this.currentInfo = this.getInfoByUrl(window.location.hash.replace('#', ''));
        this.currentUser = this.userService.authUser;
        this.userService.authUpdates.subscribe(user => {
            if (user) { this.currentUser = user;}
        });
    }

    onClickInfo() {
        if (this.currentInfo === 'liveTestingModalTpl') {
            const params = new AllRequestParams();
            params.page = 0;
            params.size = 100;
            this.stopWordsService.allMt(params).subscribe(data => {
                this.stopWords = data.content.map(_ => _.word);
            }, r => { this.stopWords = []; });
        }
        if (this.modals.includes(this.currentInfo)) {
            this.modal.alert().component(this[this.currentInfo]).open();
            this.showFabContent = false;
        }
    }

    getInfoByUrl(url: string): string {
        for (let info in this.urls) {
            if (this.urls[info].indexOf(url) !== -1) {
                return info;
            }
        }
        return '';
    }

    showDocumentaion(): boolean {
        if (this.currentNavigationPath === '') {
            this.currentNavigationPath = '/dashboard';
            this.currentLinkForDocumentation = this.modalUrlMap[this.currentNavigationPath];
            return true;
        }
        return this.pathsToShowDocumentation.includes(this.currentNavigationPath);
    }

    showPricing(): boolean {
        return !CustomUtils.getGuestUrls().includes(this.currentNavigationPath);
    }

    showHelpButton(): boolean {
        return !!this.currentInfo;
    }

    getIconForStatus(statusID: string): string {
        return CustomUtils.getIconForStatus(statusID);
    }

    openFreshChat() {
        fcWidget.open();
        this.showFabContent = false;
    }

    onClickWatchDemo() {
        this.showFabContent = false;
        window.open("https://www.youtube.com/@telqsmstestingtool", "_blank");
    }

    onClickDocumentation() {
        this.showFabContent = false;
        window.open(this.currentLinkForDocumentation, "_blank");
    }

}

interface InfoUrls {
    [name: string]: string[];
}