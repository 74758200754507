
import { AfterViewInit, Component, HostListener, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NotificationService } from '../../shared/services/notification.service';
import { Title } from "@angular/platform-browser";
import { AnalyticService, AnalyticsSource } from "../../shared/services/analytic.service";
import { DatePeriodRanges } from "../../shared/components/input/input.search.datePeriod";
import { LocalStorageService } from "../../shared/services/localStorage.service";
import { UsersService } from "../../shared/services/users.service";
import { DialogRef, ModalService } from "../../shared/services/modal.service";
import { BasicUser, Role } from "../../shared/models/user.model";
import { BrowserUtils } from "../../shared/services/browser-utils";
import { ActivatedRoute, Data } from "@angular/router";
import { FormControl } from "@angular/forms";
import { debounceTime, distinctUntilChanged, Subscription } from "rxjs";
import { AnalyticsTableComponent } from "../analytics-table/analytics-table.component";
import { GroupByCol } from "../../shared/models/analytic.model";
declare var moment: any;

@Component({
    selector: 'app-analytics-index',
    templateUrl: './analytics-index.component.html',
    styleUrls: ['analytics-index.component.scss']
})

export class AnalyticsIndexComponent implements AfterViewInit {

    @ViewChild(AnalyticsTableComponent, {static: false}) table: AnalyticsTableComponent;

    @ViewChild('headerWrapper', { static: false }) headerWrapper: any;
    @ViewChild('tableWrapper', { static: false }) tableWrapper: any;

    loading = true;

    source: AnalyticsSource = 'GLOBAL';
    startGroupBy?: GroupByCol = null;
    startGroupByLabel: string = 'All';
    startGroupByDef: {title: string, value?: GroupByCol}[] = [];

    private groupByDef: { [key: string]: {title: string, value?: GroupByCol}[] } = {
        GLOBAL: [
            {title: 'All', value: null},
            {title: 'Country', value: 'COUNTRY'},
            {title: 'Network', value: 'NETWORK'},
            {title: 'Sender type', value: 'SENDER_TYPE'},
            {title: 'Supplier title', value: 'SUPPLIER_TITLE'},
            {title: 'Supplier route type', value: 'SUPPLIER_ROUTE_TYPE'},
            {title: 'SMSC provider', value: 'SMSC_PROVIDER_NAME'},
        ],
        NTC: [
            {title: 'All', value: null},
            {title: 'Country', value: 'COUNTRY'},
            {title: 'Network', value: 'NETWORK'},
            {title: 'Sender type', value: 'SENDER_TYPE'},
            {title: 'Supplier title', value: 'SUPPLIER_TITLE'},
            {title: 'Supplier route type', value: 'SUPPLIER_ROUTE_TYPE'},
            {title: 'SMSC provider', value: 'SMSC_PROVIDER_NAME'},
        ],
        MTC: [
            {title: 'All', value: null},
            {title: 'Country', value: 'COUNTRY'},
            {title: 'Network', value: 'NETWORK'},
            {title: 'Sender type', value: 'SENDER_TYPE'},
            {title: 'SMSC provider', value: 'SMSC_PROVIDER_NAME'},
        ]
    };

    ranges: DatePeriodRanges = {
        'Last 7 Days': [moment().subtract(7, 'days'), moment().subtract(1, 'days').set({hour: 23, minute: 59})],
        'Last 30 Days': [moment().subtract(30, 'days'), moment().subtract(1, 'days').set({hour: 23, minute: 59})],
        'Last 90 Days': [moment().subtract(90, 'days'), moment().subtract(1, 'days').set({hour: 23, minute: 59})],
        'Last 365 days': [moment().subtract(365, 'days'), moment().subtract(1, 'days').set({hour: 23, minute: 59})],
    };
    rangesInStorage = {
        'Last 7 Days': '7days',
        'Last 30 Days': '30days',
        'Last 90 Days': '90days',
        'Last 365 days': 'year'
    };
    minDate: string;
    maxDate: string;
    rangeLabel: string;
    rangeStart: number;
    rangeEnd: number;
    from: string;
    to: string;

    users: BasicUser[] = [];
    userIds: number[] = [];

    usersShow = false;
    demoMode = null;

    searchControl = new FormControl();
    private searchSubscription: Subscription;

    constructor(
        public notificationService: NotificationService,
        public service: AnalyticService,
        public localStorage: LocalStorageService,
        public userService: UsersService,
        public modal: ModalService,
        private route: ActivatedRoute,
        title: Title
    ) {
        title.setTitle('Analytics');
        this.route.data.subscribe((data: Data & {source: AnalyticsSource}) => {
            this.setSource(data.source);
        });
        this.searchSubscription = this.searchControl.valueChanges.pipe(
            debounceTime(300),
            distinctUntilChanged()
        ).subscribe(searchValue => {
            this.table.search(searchValue);
        });
    }

    ngAfterViewInit() {
        this.userService.getAuthUser().then(user => {
            if (user.role !== Role.ADMIN) {
                switch (user.role) {
                    case "mainaccount":
                        this.userIds.push(user.id);
                        break;
                    case "deputy":
                        this.userIds.push(user.parentId);
                        break
                    case "subaccount":
                        this.userIds.push(user.showAllResults ? user.parentId : user.id);
                        break
                }
                this.initClient();
            } else {
                this.loading = false;
                this.usersShow = true;
                this.setDemoMode(false);
            }
        });

        this.onResize();
    }

    private initClient() {
        this.service.hasAnalytics().subscribe({
            next: (hasAnalytics) => {
                this.loading = false;
                this.setDemoMode(!hasAnalytics)
            },
            error: (err) => {
                this.loading = false;
                this.notificationService.error({
                    title: 'Analytics',
                    message: 'An error occurred while loading analytics permissions',
                    requestMessage: err.statusText,
                    requestCode: err.status
                });
            }
        })
    }

    private setDemoMode(demoMode: boolean) {
        const range = this.getRange();
        this.rangeStart = range[0].clone().unix();
        this.rangeEnd = range[1].clone().unix();
        if (demoMode) {
            delete this.ranges['Last 90 Days'];
            delete this.ranges['Last 365 days'];
            this.minDate = this.ranges['Last 30 Days'][0].format('DD/MM/YYYY');
            this.maxDate = this.ranges['Last 30 Days'][1].format('DD/MM/YYYY');
            const rangeDays = (this.rangeEnd - this.rangeStart) / (3600 * 24);
            if (rangeDays > 30) {
                this.rangeStart = this.ranges['Last 30 Days'][0].clone().unix();
                this.rangeEnd = this.ranges['Last 30 Days'][1].clone().unix();
                this.onChangeDatesRange({
                    startStr: this.ranges['Last 30 Days'][0].clone().format('YYYY-MM-DDTHH:mm:s'),
                    endStr: this.ranges['Last 30 Days'][1].clone().format('YYYY-MM-DDTHH:mm:s')
                });
            } else {
                this.setRange(range[0].format('YYYY-MM-DD'), range[1].format('YYYY-MM-DD'));
            }
        } else {
            this.minDate = this.ranges['Last 365 days'][0].format('DD/MM/YYYY');
            this.maxDate = this.ranges['Last 365 days'][1].format('DD/MM/YYYY');
            this.setRange(range[0].format('YYYY-MM-DD'), range[1].format('YYYY-MM-DD'));
        }

        this.demoMode = demoMode;
    }

    @HostListener('window:resize')
    onResize() {
        let height = this.calcHeight();
        if (height < 600) {height = 600}
        this.tableWrapper.nativeElement.style.height = `${height}px`;
    }

    private calcHeight() {
        const wrapperHeight = BrowserUtils.getPageWrapperHeight();
        if (!wrapperHeight) {return 0;}
        return wrapperHeight - this.headerWrapper.nativeElement.clientHeight - 80;
    }

    private getRange(): any[] {
        let rangeInStorage = this.localStorage.get('analytics_dates', '90days');
        let range = null;
        for (let rangeName in this.ranges) {
            if (this.rangesInStorage[rangeName] === rangeInStorage) {
                range = rangeName;
                break;
            }
        }
        if (!range) {range = Object.keys(this.ranges)[0];} else { this.rangeLabel = range; }
        return [
            this.ranges[range][0],
            this.ranges[range][1]
        ];
    }

    setRange(from: string, to: string) {
        let needUpdate = from !== this.from || to !== this.to;
        this.from = from;
        this.to = to;
        return needUpdate;
    }

    onChangeDatesRange(dates) {
        if (!dates.startStr) {
            return;
        }
        const start = dates.startStr.split('T')[0];
        const end =  dates.endStr.split('T')[0];
        let find = false;
        let rangeName = ''
        for (rangeName in this.ranges) {
            const rangeStart = this.ranges[rangeName][0].clone().format('YYYY-MM-DD');
            const rangeEnd = this.ranges[rangeName][1].clone().format('YYYY-MM-DD');
            if (start === rangeStart && end === rangeEnd) {
                find = true;
                this.localStorage.set('analytics_dates', this.rangesInStorage[rangeName]);
                break;
            }
        }
        this.rangeLabel = find ? rangeName : '';
        this.setRange(start, end);
    }

    setSource(source: AnalyticsSource) {
        this.startGroupByDef = this.groupByDef[source];
        this.startGroupBy = null;
        this.startGroupByLabel = 'All';
        this.source = source;
    }

    addUser(user: BasicUser) {
        if (this.users.filter(_ => _.id === user.id).length) {return;}
        this.users.push(user);
        this.userIds = this.users.map(_ => _.id);
    }

    removeUser(user: BasicUser) {
        this.users = this.users.filter(_ => _.id !== user.id);
        this.userIds = this.users.map(_ => _.id);
    }

    onClearUsers() {
        this.users = [];
        this.userIds = [];
    }

    setStartGroupBy(col: {title: string, value?: GroupByCol}) {
        if (this.startGroupBy === col.value) {return;}
        this.startGroupBy = col.value;
        this.startGroupByLabel = col.title;
        if (this.table) {
            this.table.updateStartGroupBy(col.value);
        }
    }
}
