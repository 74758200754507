import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MoShortCode } from '../../shared/models/mo-shortcode.model';
import { BasicUser, Role } from '../../shared/models/user.model';
import { MoShortcodeService } from '../../shared/services/mo-shortcode.service';
import { DialogRef, ModalService } from '../../shared/services/modal.service';
import { NetInfoService } from '../../shared/services/net-info.service';
import { NotificationService } from '../../shared/services/notification.service';
import { UsersService } from "../../shared/services/users.service";
import { ValidationService, Validators as Vld } from '../../shared/services/validation.service';

@Component({
    selector: 'app-mo-shortcode-form',
    templateUrl: './mo-shortcode-form.component.html',
    styleUrls: ['./mo-shortcode-form.component.scss']
})
export class MoShortcodeFormComponent implements OnInit {

    @Output() onAfterSave: EventEmitter<boolean> = new EventEmitter();

    @ViewChild('userListModalTpl', { read: TemplateRef, static: false }) userListModalTpl: any;
    userListModal: DialogRef;

    loading = false;
    countries = [];
    form: FormGroup;
    selectedUser: BasicUser = null;
    currentMoShortCodeId: number;
    isAdmin: boolean = false;

    constructor(
        public netInfoService: NetInfoService,
        public formBuilder: FormBuilder,
        public modal: ModalService,
        public moShortCodeService: MoShortcodeService,
        public notificationService: NotificationService,
        public route: ActivatedRoute,
        public router: Router,
        public validationService: ValidationService,
        private userService: UsersService
    ) { }

    ngOnInit(): void {
        this.loading = true;
        this.netInfoService.networks().subscribe({
            next: (networks) => {
                const countryMccMap = new Map<string, Set<string>>();
                networks.forEach(network => {
                    if (!countryMccMap.has(network.country.name)) {
                        countryMccMap.set(network.country.name, new Set<string>())
                    }
                    countryMccMap.get(network.country.name).add(network.mcc)
                });
                Array.from(countryMccMap.keys()).forEach(country => {
                    countryMccMap.get(country).forEach(mcc => {
                        this.countries.push({ name: country, mcc });
                    });
                });
                this.countries.sort((a, b) => a.name.localeCompare(b.name));
            }
        });
        this.form = this.formBuilder.group({
            phoneNumber: ['', Vld.compose([Vld.required, Vld.digits()])],
            country: ['', Vld.compose([Vld.required])],
            description: [''],
            enabled: [true],
            userId: ['', Vld.compose([Vld.required])],
            mcc: ['']
        });
        this.userService.getAuthUser().then(u => {
            this.isAdmin = u.role === Role.ADMIN;
            const params = this.route.snapshot.queryParams;
            this.currentMoShortCodeId = params['id'];

            if (this.currentMoShortCodeId) {
                this.loadDataForEdit(this.currentMoShortCodeId);
            } else {
                this.loading = false;
            }
            if (!this.isAdmin) {
                this.form.controls.userId.patchValue(u.id);
            }
        });
    }

    isFormValid(): boolean {
        return this.form.valid;
    }

    loadDataForEdit(moShortCodeId: number): void {
        this.moShortCodeService.one(moShortCodeId).subscribe({
            next: (res) => {
                this.form.patchValue(res);
                this.selectedUser = res.user;
                this.loading = false;
            },
            error: (err) => {
                this.notificationService.error({
                    title: 'Mobile Originated',
                    message: 'An error occurred while loading short codes.',
                    serviceName: 'NTC',
                    requestMessage: err.statusText,
                    requestCode: err.status,
                    ts: err.timestamp ? err.timestamp : null
                });
                this.router.navigate(['/mo-testing/short-codes']).then();
            }
        });
    }

    onSubmit(): void {
        const moShortCodeFormData: MoShortCode = this.form.getRawValue();
        if (this.currentMoShortCodeId) {
            this.updateMOShortCode(this.currentMoShortCodeId, moShortCodeFormData);
            return;
        }
        this.createMOShortCode(moShortCodeFormData);
    }

    createMOShortCode(moShortCodeFormData: MoShortCode): void {
        this.loading = true;
        this.moShortCodeService.createMoShortCode([moShortCodeFormData]).subscribe({
            next: (res) => {
                if (res[0].status === 'DUPLICATED') {
                    this.notificationService.error({
                        title: 'Mobile Originated',
                        message: 'Short code already exists.',
                        serviceName: 'MO'
                    });
                } else {
                    this.notificationService.success('Short code added.', 'Mobile Originated');
                    this.onAfterSave.emit(true);
                }
                this.loading = false;
            },
            error: (err) => {
                this.notificationService.error({
                    title: 'Mobile Originated',
                    message: 'An error occurred while creating short code.',
                    serviceName: 'MO',
                    requestMessage: err.statusText,
                    requestCode: err.status,
                    ts: err.timestamp ? err.timestamp : null
                });
                this.loading = false;
            }
        });
    }

    updateMOShortCode(phoneNumber: number, moShortCodeFormData: MoShortCode): void {
        this.moShortCodeService.updateMoShortCode(phoneNumber, moShortCodeFormData).subscribe({
            next: (res) => {
                this.notificationService.success('MO Short code updated.', 'Mobile Originated');
                this.onAfterSave.emit(true);
            },
            error: (err) => {
                this.notificationService.error({
                    title: 'Mobile Originated',
                    message: 'An error occurred while updating short code.',
                    serviceName: 'MO',
                    requestMessage: err.statusText,
                    requestCode: err.status,
                    ts: err.timestamp ? err.timestamp : null
                });
            }
        });
    }

    onCountryChange(value: string): void {
        const mcc = value.match(/\d+/);
        this.form.get('mcc').setValue(mcc[0]);
    }

    showUserListModal(): void {
        this.userListModal = this.modal.alert().component(this.userListModalTpl).open();
    }

    getValue(country: any): string {
        return `${country.name} (${country.mcc})`;
    }

    onSelectUser(user) {
        this.selectedUser = user;
        this.form.get('userId').setValue(user.id);
        if (this.userListModal) {
            this.userListModal.close(true);
        }
    }
}
