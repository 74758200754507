import { SessionSupplierIndexComponent } from './sessions-suppliers/session-suppliers-index/session-supplier-index.component';
import { DashboardIndexComponent } from './dashboard/dashboard-index/dashboard-index.component';
import { NetworkIndexComponent } from './network-info/network-index/network-index.component';
import { CountryIndexComponent } from './network-info/country-index/country-index.component';
import { SchedulerIndexComponent } from './scheduler/scheduler-index/scheduler-index.component';
import { SchedulerReportsIndexComponent } from './scheduler/scheduler-reports-index/scheduler-reports-index.component';

import { StopWordsIndexComponent } from './stop-words/stop-words-index/stop-words-index.component';

import { Routes } from "@angular/router";
import { AnalyticsIndexComponent } from "./analytics/analytics-index/analytics-index.component";
import { ApiSettingsComponent } from "./api/api-settings/api-settings.component";
import {
    AppscriptsMoSmsIndexComponent
} from "./appscripts/appscripts-mo-sms/appscripts-mo-sms-index/appscripts-mo-sms-index.component";
import {
    AppscriptsPaymentMethodIndexComponent
} from './appscripts/appscripts-payment-method/appscripts-payment-method-index/appscripts-payment-method-index.component';
import {
    AppscriptsPaymentRequestIndexComponent
} from './appscripts/appscripts-payment-request/appscripts-payment-request-index/appscripts-payment-request-index.component';
import {
    AppscriptsSmsIndexComponent
} from './appscripts/appscripts-sms/appscripts-sms-index/appscripts-sms-index.component';
import {
    AppscriptsUserIndexComponent
} from './appscripts/appscripts-user/appscripts-user-index/appscripts-user-index.component';
import { AuditLogIndexComponent } from "./audit-log/audit-log-index/audit-log-index.component";
import { AuditLogIndexComponentV2 } from "./audit-log-v2/audit-log-index/audit-log-index.component";
import { LoginComponent } from "./auth/login/login.component";
import { RegistrationComponent } from "./auth/registration/registration.component";
import { ResetPasswordTokenComponent } from "./auth/reset-password-token/reset-password-token.component";
import { ResetPasswordComponent } from "./auth/reset-password/reset-password.component";
import { TokenComponent } from "./auth/token/token.component";
import { ContentTemplatesIndexComponent } from './content-templates/content-templates-index/content-templates-index.component';
import { Error403Component } from './help/403/error-403.component';
import { Error404Component } from './help/404/error-404.component';
import { InvoicesCreateComponent } from "./invoices/invoices-create/invoices-create.component";
import { InvoicesIndexComponent } from "./invoices/invoices-index/invoices-index.component";
import { InvoicesViewComponent } from "./invoices/invoices-view/invoices-view.component";
import { MoShortcodeIndexComponent } from './mo-shortcode/mo-shortcode-index/mo-shortcode-index.component';
import { PhoneReportsIndexComponent } from "./phone-reports/phone-reports-index/phone-reports-index.component";
import { PricingGroupCreateComponent } from "./pricing/general/pricing-group-create/pricing-group-create.component";
import { PricingGroupIndexComponent } from "./pricing/general/pricing-group-index/pricing-group-index.component";
import { PricingGroupUpdateComponent } from "./pricing/general/pricing-group-update/pricing-group-update.component";
import { PackagesIndexComponent } from "./pricing/packages/packages-index/packages-index.component";
import { SpecialPricingGroupClientComponent } from "./pricing/special/special-pricing-group-client/special-pricing-group-client.component";
import { SpecialPricingGroupCreateComponent } from "./pricing/special/special-pricing-group-create/special-pricing-group-create.component";
import { SpecialPricingGroupIndexComponent } from "./pricing/special/special-pricing-group-index/special-pricing-group-index.component";
import { SpecialPricingGroupUpdateComponent } from "./pricing/special/special-pricing-group-update/special-pricing-group-update.component";
import { SettingIndexComponent } from "./setting/setting-index/setting-index.component";
import { AuthGuard } from "./shared/auth-guard";
import { Role } from "./shared/models/user.model";
import { TestCaseTemplateIndexComponent } from './test-case-templates/test-case-template-index/test-case-template-index.component';
import { TestCaseTemplateUpdateComponent } from './test-case-templates/test-case-template-update/test-case-template-update.component';
import { LntIndexComponent } from './test/lnt/lnt-index/lnt-index.component';
import { MntIndexComponent } from './test/mnt/mnt-index/mnt-index.component';
import { MoIndexComponent } from "./test/mo/mo-index/mo-index.component";
import { AccountIndexComponent } from './users/account-index/account-index.component';
import { SubaccountsIndexComponent } from './users/subaccounts-index/subaccounts-index.component';
import { UsersCreateComponent } from './users/users-create/users-create.component';
import { UsersIndexComponent } from './users/users-index/users-index.component';
import { UsersUpdateComponent } from './users/users-update/users-update.component';
import { CoverageIndexComponent } from "./analytics/coverage-index/coverage-index.component";
import { CallbackHistoryIndexComponent } from "./api/callback-history/callback-history-index/callback-history-index.component";
import { VtIndexComponent } from "./test/vt/vt-index/vt-index.component";
import { AppscriptsMtTierIndexComponent } from "./appscripts/appscripts-mt-tier/appscripts-mt-tier-index/appscripts-mt-tier-index.component";
import { AppscriptsCliIndexComponent } from './appscripts/appscripts-cli/appscripts-cli-index/appscripts-cli-index.component';
import { AppscriptsSettingIndexComponent } from "./appscripts/appscripts-settings/appscripts-settings-index/appscripts-setting-index.component";
import { SipConfigIndexComponent } from "./sip/config/sip-config-index/sip-config-index.component";

const guestData = { roles: [Role.GUEST] };
const adminData = { roles: [Role.ADMIN] };
const nonGuestData = { roles: [Role.MAIN, Role.DEPUTY, Role.SUB, Role.ADMIN] };
const adminMainData = { roles: [Role.MAIN, Role.DEPUTY, Role.ADMIN] };

export const routes: Routes = [
    { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
    { path: 'logout', redirectTo: 'login', pathMatch: 'full' },
    { path: 'login', component: LoginComponent, canActivate: [AuthGuard], data: guestData },
    { path: 'registration', component: RegistrationComponent, canActivate: [AuthGuard], data: guestData },
    { path: 'registration/thankyou', redirectTo: 'registration', pathMatch: 'full' },
    { path: 'reset-password', component: ResetPasswordComponent, canActivate: [AuthGuard], data: guestData },
    { path: 'email/verify', component: TokenComponent, canActivate: [AuthGuard], data: guestData },
    { path: 'token/recovery', component: ResetPasswordTokenComponent, canActivate: [AuthGuard], data: guestData },

    { path: 'dashboard', component: DashboardIndexComponent, canActivate: [AuthGuard], data: nonGuestData },

    { path: 'sessions', component: SessionSupplierIndexComponent, canActivate: [AuthGuard], data: nonGuestData },

    { path: 'users', component: UsersIndexComponent, canActivate: [AuthGuard], data: adminMainData },
    { path: 'users/subaccounts', component: SubaccountsIndexComponent, canActivate: [AuthGuard], data: adminMainData },
    { path: 'users/create', component: UsersCreateComponent, canActivate: [AuthGuard], data: adminData },
    { path: 'users/account', component: AccountIndexComponent, canActivate: [AuthGuard], data: nonGuestData },
    { path: 'users/:id', component: UsersUpdateComponent, canActivate: [AuthGuard], data: adminData },

    { path: 'content-templates', component: ContentTemplatesIndexComponent, canActivate: [AuthGuard], data: nonGuestData },

    { path: 'live-testing', component: LntIndexComponent, canActivate: [AuthGuard], data: nonGuestData },

    { path: 'test-case-templates', component: TestCaseTemplateIndexComponent, canActivate: [AuthGuard], data: nonGuestData },
    { path: 'test-case-templates/:id', component: TestCaseTemplateUpdateComponent, canActivate: [AuthGuard], data: nonGuestData },

    { path: 'manual-testing', component: MntIndexComponent, canActivate: [AuthGuard], data: nonGuestData },

    { path: 'mo-testing', component: MoIndexComponent, canActivate: [AuthGuard], data: Object.assign({requirePermission: 'mo-testing'}, nonGuestData) },
    { path: 'mo-testing/short-codes', component: MoShortcodeIndexComponent, canActivate: [AuthGuard], data: nonGuestData },

    { path: 'cli-testing', component: VtIndexComponent, canActivate: [AuthGuard], data: Object.assign({requirePermission: 'cli-testing'}, nonGuestData) },

    { path: 'pricing-general', component: PricingGroupIndexComponent, canActivate: [AuthGuard], data: adminData },
    { path: 'pricing-general/create', component: PricingGroupCreateComponent, canActivate: [AuthGuard], data: adminData },
    { path: 'pricing-general/:id', component: PricingGroupUpdateComponent, canActivate: [AuthGuard], data: adminData },

    { path: 'pricing-special', component: SpecialPricingGroupIndexComponent, canActivate: [AuthGuard], data: adminData },
    { path: 'pricing-special/create', component: SpecialPricingGroupCreateComponent, canActivate: [AuthGuard], data: adminData },
    { path: 'pricing-special/:id', component: SpecialPricingGroupUpdateComponent, canActivate: [AuthGuard], data: adminData },

    { path: 'invoices', component: InvoicesIndexComponent, canActivate: [AuthGuard], data: adminMainData },
    { path: 'invoices/create', component: InvoicesCreateComponent, canActivate: [AuthGuard], data: adminData },
    { path: 'invoices/:id', component: InvoicesViewComponent, canActivate: [AuthGuard], data: adminMainData },

    { path: 'billing/prepaid', component: PackagesIndexComponent, canActivate: [AuthGuard], data: nonGuestData },
    { path: 'billing/postpaid', component: SpecialPricingGroupClientComponent, canActivate: [AuthGuard], data: adminMainData },

    { path: 'user/account/api', component: ApiSettingsComponent, canActivate: [AuthGuard], data: nonGuestData },
    { path: 'user/account/callback-history', component: CallbackHistoryIndexComponent, canActivate: [AuthGuard], data: nonGuestData },
    { path: 'stop-words', component: StopWordsIndexComponent, canActivate: [AuthGuard], data: adminData },

    { path: 'net-info/networks', component: NetworkIndexComponent, canActivate: [AuthGuard], data: adminData },
    { path: 'net-info/countries', component: CountryIndexComponent, canActivate: [AuthGuard], data: adminData },

    { path: 'appscripts/numbers', component: AppscriptsUserIndexComponent, canActivate: [AuthGuard], data: adminData },
    { path: 'appscripts/payment-requests', component: AppscriptsPaymentRequestIndexComponent, canActivate: [AuthGuard], data: adminData },
    { path: 'appscripts/sms', component: AppscriptsSmsIndexComponent, canActivate: [AuthGuard], data: adminData },
    { path: 'appscripts/outgoing-sms', component: AppscriptsMoSmsIndexComponent, canActivate: [AuthGuard], data: adminData },
    { path: 'appscripts/payment-methods', component: AppscriptsPaymentMethodIndexComponent, canActivate: [AuthGuard], data: adminData },
    { path: 'appscripts/mt-tiers', component: AppscriptsMtTierIndexComponent, canActivate: [AuthGuard], data: adminData },
    { path: 'appscripts/cli', component: AppscriptsCliIndexComponent, canActivate: [AuthGuard], data: adminData },
    { path: 'appscripts/settings', component: AppscriptsSettingIndexComponent, canActivate: [AuthGuard], data: adminData },

    { path: 'phone-reports', component: PhoneReportsIndexComponent, canActivate: [AuthGuard], data: adminData },

    { path: 'scheduler/tasks', component: SchedulerIndexComponent, canActivate: [AuthGuard], data: nonGuestData },
    { path: 'scheduler/reports', component: SchedulerReportsIndexComponent, canActivate: [AuthGuard], data: nonGuestData },

    { path: 'settings', component: SettingIndexComponent, canActivate: [AuthGuard], data: adminData },

    { path: 'analytics/global', component: AnalyticsIndexComponent, canActivate: [AuthGuard], data: Object.assign({ source: 'GLOBAL' }, nonGuestData) },
    { path: 'analytics/live-testing', component: AnalyticsIndexComponent, canActivate: [AuthGuard], data: Object.assign({ source: 'NTC' }, nonGuestData) },
    { path: 'analytics/manual-testing', component: AnalyticsIndexComponent, canActivate: [AuthGuard], data: Object.assign({ source: 'MTC' }, nonGuestData) },
    { path: 'analytics/coverage', component: CoverageIndexComponent, canActivate: [AuthGuard], data: nonGuestData },

    { path: 'audit/logs', component: AuditLogIndexComponent, canActivate: [AuthGuard], data: nonGuestData },
    { path: 'audit/logs/v2', component: AuditLogIndexComponentV2, canActivate: [AuthGuard], data: nonGuestData },

    { path: 'sip/config', component: SipConfigIndexComponent, canActivate: [AuthGuard], data: nonGuestData },

    {
        path: 'status',
        canActivate: [AuthGuard], data: nonGuestData,
        loadChildren: () => import('./status-module/status-module.module').then(m => m.StatusModule),
    },

    { path: '403', component: Error403Component },
    { path: '404', component: Error404Component },
    { path: '**', redirectTo: '/404' }
];

