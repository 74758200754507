import { Component, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NotificationService } from '../../shared/services/notification.service';
import { ApiSettings } from "../../shared/models/api.model";
import { UserApiService } from "../../shared/services/api.service";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { ValidationService, Validators as Vld } from "../../shared/services/validation.service";
import { UsersService } from '../../shared/services/users.service';
import { ModalService, DialogRef } from "../../shared/services/modal.service";
import { catchError } from "rxjs/operators";
import { forkJoin, throwError } from "rxjs";
import { Role } from "../../shared/models/user.model";

@Component({
    selector: 'app-api-settings',
    templateUrl: './api-settings.component.html',
    styleUrls: ['./api-settings.component.scss'],
})

export class ApiSettingsComponent implements OnInit {

    @ViewChild('regenarateModal', { read: TemplateRef, static: false }) regenarateModal: any;

    model: ApiSettings;
    allowedIps: string[] = [];
    userId: number;
    loading: boolean = false;

    form: FormGroup;

    callbackUrlLiveTesting = '';
    generalCallbackUrlLiveTesting = '';
    isMain = false;
    formLnt: FormGroup;

    constructor(
        public service: UserApiService,
        public userService: UsersService,
        public router: Router,
        public notificationService: NotificationService,
        titleService: Title,
        formBuilder: FormBuilder,
        public validationService: ValidationService,
        public modal: ModalService
    ) {
        titleService.setTitle('API settings');
        this.model = service.createSettings();
        this.form = formBuilder.group({
            enabled: [''],
            appKey: [''],
            allowedIps: ['', Vld.maxLength(500)],
            ip: ['', Vld.compose([Vld.ip(true)])],
        });
        this.formLnt = formBuilder.group({
            callbackUrlLiveTesting: ['', Vld.compose([Vld.url(true)])],
            generalCallbackUrlLiveTesting: ['', Vld.compose([Vld.url(true)])],
        });
    }

    ngOnInit() {
        this.loading = true;
        this.userService.getAuthUser().then(i => {
            this.userId = i.id;
            this.isMain = i.role === Role.MAIN || i.role === Role.DEPUTY;
            this.init();
        });
    }

    init() {
        this.userService.getCallbackUrlLiveTesting().subscribe(callbackUrlLiveTesting => {
            this.callbackUrlLiveTesting = callbackUrlLiveTesting ? callbackUrlLiveTesting : '';
            if (callbackUrlLiveTesting) {
                this.formLnt.controls.callbackUrlLiveTesting.setValue(callbackUrlLiveTesting);
            }
        });
        if (this.isMain) {
            this.userService.getGeneralCallbackUrlLiveTesting().subscribe(url => {
                this.generalCallbackUrlLiveTesting = url ? url : '';
                if (url) {
                    this.formLnt.controls.generalCallbackUrlLiveTesting.setValue(url);
                }
            });
        }
        this.service.getSettings(this.userId).subscribe({
            next: settings => {
                this.model = settings;
                if (settings.allowedIps) {
                    this.allowedIps = settings.allowedIps.split(' ')
                        .map(_ => _.trim())
                        .filter(_ => _.length > 0);
                }
                this.loading = false;
            },
            error: e => {
                this.loading = false;
                if (e.status === 404) {
                    this.model = this.service.createSettings();
                    this.model.userId = this.userId;
                    return;
                }
                this.notificationService.error({
                    title: 'Api settings',
                    message: 'Receive settings an error',
                    serviceName: 'GATEWAY',
                    requestMessage: e.statusText,
                    requestCode: e.status
                });
            }
        });
    }

    onSubmit() {
        this.loading = true;
        if (!this.model.appKey) {
            this.model.appKey = null;
        }
        this.model.allowedIps = this.allowedIps.join(' ');
        this.service.saveSettings(this.model).subscribe({
            next: res => {
                this.notificationService.success('API settings updated', 'API settings');
                if (!res.appId) {
                    this.service.getSettings(this.userId).subscribe(settings => {
                        this.model.appId = settings.appId;
                    });
                }
                this.model.appId = res.appId;
                this.loading = false;
            },
            error: e => {
                this.loading = false;
                this.notificationService.error({
                    title: 'Api settings',
                    message: 'Update an error',
                    serviceName: 'GATEWAY',
                    requestMessage: e.statusText,
                    requestCode: e.status
                });
            }
        });
    }

    onClickGenerate() {
        const regenarateModalDialogRef = this.modal.alert().component(this.regenarateModal).dialogClass('modal-dialog small-modal').open();
        regenarateModalDialogRef.result.then(result => {
            if (result) {
                this.generateKey();
                this.loading = true;
                this.service.saveAppKey(this.model.appKey).subscribe({
                    next: _ => {
                        this.notificationService.success('App key updated', 'API settings');
                        this.loading = false;
                    },
                    error: e => {
                        this.loading = false;
                        this.notificationService.error({
                            title: 'API settings',
                            message: 'Update an error',
                            serviceName: 'GATEWAY',
                            requestMessage: e.statusText,
                            requestCode: e.status
                        });
                    }
                });
            }
        });
    }

    onChangeEnabled(enabled) {
        this.model.enabled = enabled;
        if (!this.model.appId && this.model.enabled) {
            this.generateKey();
            this.onSubmit();
        }
    }

    generateKey() {
        let key = this.randomString(35);
        this.form.controls.appKey.patchValue(key);
        this.model.appKey = key;
    }

    randomString(m) {
        let s = '', r = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_-=+-/';
        for (let i = 0; i < m; i++) { s += r.charAt(Math.floor(Math.random() * r.length)); }
        return s;
    }

    addIpToList(): void {
        const ipFormControl: FormControl = this.form.controls.ip as FormControl;
        const ipValue = ipFormControl.value?.trim();
        if (this.form.valid && ipValue.length > 0 && !this.allowedIps.includes(ipValue)) {
            this.allowedIps.push(ipFormControl.value);
            this.form.get('ip').patchValue('');
        }
    }

    removeIpFromList(ip: string): void {
        this.allowedIps = this.allowedIps.filter(ai => ai !== ip);
    }

    async onClickCopy(textToCopy: string): Promise<void> {
        try {
            await navigator.clipboard.writeText(textToCopy);
            this.notificationService.success('Copied to clipboard', 'API settings');
        } catch (err) {
            this.notificationService.error({
                title: 'API settings',
                message: 'Fail to copy to clipboard. Please copy it manually.',
                serviceName: 'GATEWAY',
            });
        }
    }

    onSubmitLnt() {
        this.loading = true;
        if (this.callbackUrlLiveTesting && !this.callbackUrlLiveTesting.startsWith('http')) {
            this.callbackUrlLiveTesting = `https://${this.callbackUrlLiveTesting}`;
            this.formLnt.controls.callbackUrlLiveTesting.setValue(this.callbackUrlLiveTesting);
        }
        if (this.generalCallbackUrlLiveTesting && !this.generalCallbackUrlLiveTesting.startsWith('http')) {
            this.generalCallbackUrlLiveTesting = `https://${this.generalCallbackUrlLiveTesting}`;
            this.formLnt.controls.generalCallbackUrlLiveTesting.setValue(this.generalCallbackUrlLiveTesting);
        }
        let requests = [
            this.userService.updateCallbackUrlLiveTesting(this.callbackUrlLiveTesting)
        ];
        if (this.isMain) {
            requests.push(this.userService.updateGeneralUpdateCallbackUrl(this.generalCallbackUrlLiveTesting))
        }
        forkJoin(requests)
            .pipe(
                catchError(e => {
                    this.loading = false;
                    this.notificationService.error({
                        title: 'API settings',
                        message: 'Update an error',
                        serviceName: 'GATEWAY',
                        requestMessage: e.statusText,
                        requestCode: e.status
                    });
                    return throwError(() => e);
                })
            )
            .subscribe(() => {
                this.notificationService.success('Callback url updated', 'API settings');
                this.loading = false;
            });
    }
}
