
import { Component, OnInit } from '@angular/core';
import { AuthUser, Role } from '../../shared/models/user.model';
import { UsersService } from '../../shared/services/users.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-balance-widget',
    templateUrl: 'balance-widget.component.html',
    styleUrls: ['balance-widget.component.scss']
})

export class BalanceWidgetComponent implements OnInit {

    currentValue: number = 0;
    user: AuthUser;

    constructor(
        public service: UsersService,
        private router: Router
    ) { }

    ngOnInit() {
        this.service.balanceUpdates.subscribe(balance => {
            this.currentValue = balance;
            this.service.getAuthUser().then(user => {
                this.user = user;
            });
        });
    }

    onBalanceWidgetClick(): void {
        if (this.user?.paymentType === 'PREPAID' || this.user?.role === Role.ADMIN) {
            this.router.navigate(['/billing/prepaid']);
        }
    }

}
