
import { AfterViewInit, Component, HostListener, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NotificationService } from '../../shared/services/notification.service';
import { Title } from "@angular/platform-browser";
import { UsersService } from "../../shared/services/users.service";
import { DialogRef, ModalService } from "../../shared/services/modal.service";
import { BrowserUtils } from "../../shared/services/browser-utils";
import { CoverageTableComponent } from "../coverage-table/coverage-table.component";
import { FormControl } from "@angular/forms";
import { catchError, debounceTime, distinctUntilChanged, filter, Subscription, throwError } from "rxjs";
import { CoverageNetwork } from "../../shared/models/coverage.model";
import { CoverageService } from "../../shared/services/coverage.service";
import { Role } from "../../shared/models/user.model";


@Component({
    selector: 'app-coverage-index',
    templateUrl: './coverage-index.component.html',
    styleUrls: ['coverage-index.component.scss']
})

export class CoverageIndexComponent implements OnInit, AfterViewInit {

    @ViewChild(CoverageTableComponent, {static: false}) table: CoverageTableComponent;

    @ViewChild('tableWrapper', { static: false }) tableWrapper: any;
    @ViewChild('headerWrapper', { static: false }) headerWrapper: any;

    @ViewChild('coverageMapModalTpl', { read: TemplateRef, static: false }) coverageMapModalTpl: any;

    loading = false;
    isAdmin = false;

    searchControl = new FormControl();
    private searchSubscription: Subscription;

    networks: CoverageNetwork[] = [];

    constructor(
        private notificationService: NotificationService,
        private userService: UsersService,
        private service: CoverageService,
        private modal: ModalService,
        title: Title
    ) {
        title.setTitle('Coverage');
    }

    ngOnInit() {
        this.searchSubscription = this.searchControl.valueChanges.pipe(
            debounceTime(300),
            distinctUntilChanged()
        ).subscribe(searchValue => {
            this.table.search(searchValue.trim())
        });
        this.loading = true;
        this.service.networks().pipe(
            catchError(e => {
                this.notificationService.error({
                    title: 'Coverage',
                    message: 'An error occurred while loading coverage',
                    requestMessage: e.statusText,
                    requestCode: e.status
                });
                this.loading = false;
                return throwError(() => e);
            })
        ).subscribe(networks => {
            this.networks = networks;
            this.loading = false;
        });
        this.userService.getAuthUser().then(u => {
            this.isAdmin = u.role === Role.ADMIN;
        });
    }

    ngAfterViewInit() {
        this.onResize();
    }

    onClickMap(): void {
        this.modal.alert().dialogClass('modal-dialog max-large-modal').component(this.coverageMapModalTpl).open();
    }

    @HostListener('window:resize')
    onResize() {
        let height = this.calcHeight();
        if (height < 600) {height = 600}
        this.tableWrapper.nativeElement.style.height = `${height}px`;
    }

    private calcHeight() {
        const wrapperHeight = BrowserUtils.getPageWrapperHeight();
        if (!wrapperHeight) {
            return 0;
        }
        return wrapperHeight - this.headerWrapper.nativeElement.clientHeight - 140;
    }
}
