import { throwError} from 'rxjs';
import { HttpErrors } from './http-errors';
import { BrowserUtils } from './browser-utils';
import { HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { ApiGatewayError } from "../models/errors";

export class RestUtils {

    static _role = 'guest';

    buildUrl(url: string, queryParams: any = {}, includeRole: boolean = true): string {
        let role = '';
        if (includeRole) {
            role = RestUtils.getRole();
        }
        if (!url.includes('https://') && !url.includes('http://')) {
            url = 'API_BASE_URL' + (url.charAt(0) === '/' ? '' : '/') + url;
        }
        url = url
            .replace('ROLE/', role ? role + '/' : '')
            .replace('ROLE', role);

        const isValidQueryParam = (value: any) =>
            value !== null && value !== undefined;

        const query: string[] = [];
        for (const key in queryParams) {
            const value = queryParams[key];

            if (Array.isArray(value)) {
                value.forEach(item => {
                    if (isValidQueryParam(item)) {
                        query.push(`${encodeURIComponent(key)}=${encodeURIComponent(item)}`);
                    }
                });
            } else if (isValidQueryParam(value)) {
                query.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
            }
        }

        if (query.length) {
            url += '?' + query.join('&');
        }
        return url;
    }

    handleError(error: HttpErrorResponse) {
        if (error.headers.has('X-XSRF-TOKEN')) {
            BrowserUtils.setXsrf(error.headers.get('X-XSRF-TOKEN'));
        }
        let apiGatewayError: ApiGatewayError;
        if (error.status !== 0 && error.error && error.error.message) {
            apiGatewayError = error.error;
        } else if (error.status !== 0 && typeof error.error === "string") {
            apiGatewayError = {
                timestamp: null,
                path: null,
                status: error.status,
                error: error.error,
                message: error.error,
                requestId: null
            }
        }

        let status = error.status;
        if (apiGatewayError && apiGatewayError.message === 'Invalid token') {
            status = 401;
        }

        HttpErrors.status.next(status);

        return throwError(() => apiGatewayError ? {
            status: apiGatewayError.status,
            statusText: apiGatewayError.message,
            requestId: apiGatewayError.requestId,
            timestamp: apiGatewayError.timestamp,
            path: apiGatewayError.path,
            errorText: apiGatewayError.error
        } : error);
    }

    handleResponse(response: HttpResponse<any>) {
        if (response.headers.has('X-XSRF-TOKEN')) {
            BrowserUtils.setXsrf(response.headers.get('X-XSRF-TOKEN'));
        }
    }

    getHttpHeaderOptions(headers: HttpHeaders, insertXsrf = true) {
        headers = headers instanceof HttpHeaders ? headers : new HttpHeaders();
        if (insertXsrf) {
            const xsrf = BrowserUtils.getXsrf();
            if (xsrf) {
                headers = headers.set('X-XSRF-TOKEN', xsrf);
            }
        }
        return {
            headers: headers,
            withCredentials: true
        }
    }

    static getRole() {
        return RestUtils._role;
    }
}
