import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { Invoice } from '../../../shared/models/invoice.model';
import { InvoiceService } from '../../../shared/services/invoice.service';
import { AuthUser, Role } from '../../../shared/models/user.model';
import { UsersService } from '../../../shared/services/users.service';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'app-invoice-table-actions',
    templateUrl: './invoice-table-actions.component.html',
    styleUrls: ['./invoice-table-actions.component.scss']
})
export class InvoiceTableActionsComponent {

    user: AuthUser;
    currentRow: Invoice;

    constructor(private invoiceService: InvoiceService, private userService: UsersService) { }

    agInit(params: ICellRendererParams): void {
        this.currentRow = params.data;
        this.userService.getAuthUser().then(user => {
            this.user = user;
        }).catch(error => {
            console.error(error);
        });
    }

    refresh(params: ICellRendererParams) {
        return true;
    }

    onClick(clickedItem: string) {
        this.invoiceService.announceAction({ name: clickedItem, row: this.currentRow, column: 'action' });
    }

    canShowActionDelete() {
        const usersToShowInvoiceDelete = environment['showInvoiceDeleteToUserIds'];
        if (usersToShowInvoiceDelete.has(this.user?.id) && this.currentRow.paymentType === 'PREPAID' && this.currentRow.paid) {
            return true;
        }
        if (this.currentRow.autoGenerated) return false;
        return this.currentRow.status === 'WAITING' && (!this.currentRow.reportReference && !this.currentRow.zohoId);
    }

    protected readonly Role = Role;
}
