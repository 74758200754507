import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable} from '@angular/core';
import { Observable } from 'rxjs';
import { RestUtils } from './rest-utils';
import { SipConfig, SipConfigUpdate } from "../models/sip.model";
import { map } from "rxjs/operators";

@Injectable({providedIn: "root"})
export class SipService {

    http: HttpClient;

    utils = new RestUtils();

    headers = new HttpHeaders();

    readonly protocols = ['UDP', 'TCP'];

    constructor(http: HttpClient) {
        this.http = http;
        this.headers = this.headers.set('Content-Type', 'application/json');
    }

    allConfig(): Observable<SipConfig[]> {
        let url = this.utils.buildUrl('ROLE/sip/sip-configs');
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<SipConfig[]>(url, options).pipe(
            map(configs => configs.map(this.prepareConfig))
        );
    }

    oneConfig(id: number): Observable<SipConfig> {
        let url = this.utils.buildUrl('ROLE/sip/sip-configs/' + id);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<SipConfig>(url, options).pipe(
            map(this.prepareConfig)
        );
    }

    saveConfig(config: SipConfigUpdate): Observable<SipConfig> {
        let options = this.utils.getHttpHeaderOptions(this.headers);
        if (config.id) {
            let url = this.utils.buildUrl('ROLE/sip/sip-configs/' + config.id);
            return this.http.put<SipConfig>(url, config, options);
        }

        let url = this.utils.buildUrl('ROLE/sip/sip-configs');
        return this.http.post<SipConfig>(url, config, options);
    }

    private prepareConfig(config: SipConfig): SipConfig {
        config.createdAt = new Date(config.createdAt);
        if (config.updatedAt) {
            config.updatedAt = new Date(config.updatedAt);
        }
        config.batch = false;
        return config;
    }

    deleteConfig(id: number): Observable<any> {
        let url = this.utils.buildUrl('ROLE/sip/sip-configs/' + id);
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.delete(url, options);
    }

    createConfig(): SipConfigUpdate {
        return {
            id: null,
            name: '',
            host: '',
            port: 5060,
            protocol: 'UDP',
            prefix: null,
            enabled: true
        }
    }
}

