
import {
    Component,
    EventEmitter,
    Input, OnInit,
    Output
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SipConfig, SipConfigUpdate } from "../../../shared/models/sip.model";
import { NotificationService } from "../../../shared/services/notification.service";
import { SipService } from "../../../shared/services/sip.service";
import { ValidationService , Validators as Vld} from "../../../shared/services/validation.service";
import { catchError, throwError } from "rxjs";

@Component({
    selector: 'app-sip-config-form',
    templateUrl: './sip-config-form.component.html',
    styleUrls: ['./sip-config-form.component.scss']
})

export class SipConfigFormComponent implements OnInit {

    @Input() backButtonShow = true;
    @Input() model: SipConfigUpdate;

    @Output() onAfterSave = new EventEmitter<SipConfig>();

    loading = false;

    form: FormGroup;

    constructor(
        private notificationService: NotificationService,
        private formBuilder: FormBuilder,
        public service: SipService,
        public validationService: ValidationService
    ) {

    }

    ngOnInit() {
        this.form = this.formBuilder.group({
            id: [this.model.id],
            name: [this.model.name, Vld.compose([Vld.required, Vld.minLength(1), Vld.maxLength(255)])],
            host: [this.model.host, Vld.compose([Vld.required, Vld.domainOrIp(true), Vld.maxLength(255)])],
            port: [this.model.port, Vld.compose([Vld.required, Vld.integer(true)])],
            protocol: [this.model.protocol, Vld.compose([Vld.required])],
            prefix: [this.model.prefix, Vld.compose([Vld.maxLength(255)])],
            enabled: [this.model.enabled]
        });
    }

    onSubmit() {
        this.loading = true;
        const data = this.form.getRawValue() as SipConfigUpdate;
        this.service.saveConfig(data).pipe(
            catchError(e => {
                this.loading = false;
                this.notificationService.error({
                    title: 'SIP config',
                    message: 'An error occurred while creating/updating the config',
                    serviceName: 'SIP',
                    requestMessage: e.statusText,
                    requestCode: e.status,
                    ts: e.timestamp ? e.timestamp : null
                });
                return throwError(() => e);
            })
        ).subscribe(d => {
            this.loading = false;
            this.onAfterSave.emit(d);
            this.notificationService.success('Config ' + (data.id ? 'updated' : 'created'), 'SIP config');
        });
    }
}
