import { Component, TemplateRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { SipConfigTableComponent } from "../sip-config-table/sip-config-table.component";
import { SipService } from "../../../shared/services/sip.service";
import { NotificationService } from "../../../shared/services/notification.service";
import { DialogRef, ModalService } from "../../../shared/services/modal.service";
import { BatchTasks } from "../../../shared/services/batch-tasks";
import { SipConfig, sipConfigToUpdate, SipConfigUpdate } from "../../../shared/models/sip.model";
import { SipConfigTableAction } from "../sip-config-table/sip-config-table-actions/sip-config-table-actions.component";


@Component({
    selector: 'app-sip-config-index',
    templateUrl: './sip-config-index.component.html',
    styleUrls: [],
})

export class SipConfigIndexComponent {

    @ViewChild(SipConfigTableComponent, {static: false}) table: SipConfigTableComponent;

    searchControl = new FormControl();
    private searchSubscription: Subscription;

    selectedRows: SipConfig[] = [];
    batchCompleted = 0;
    progressShow = false;

    editModel: SipConfigUpdate = null;
    @ViewChild('formModalTpl', { read: TemplateRef, static: false }) formModalTpl: any;
    formModal: DialogRef;

    constructor(
        public service: SipService,
        public router: Router,
        public notificationService: NotificationService,
        titleService: Title,
        public modal: ModalService
    ) {
        titleService.setTitle('SIP config');
    }

    ngOnInit() {
        this.searchSubscription = this.searchControl.valueChanges.pipe(
            debounceTime(500),
            distinctUntilChanged()
        ).subscribe(searchValue => {
            this.onChangeInputSearch(searchValue);
        });
    }

    onCreate() {
        this.editModel = this.service.createConfig();
        this.formModal = this.modal.alert().component(this.formModalTpl).open();
    }


    onEdit(config: SipConfig) {
        this.editModel = sipConfigToUpdate(config);
        this.formModal = this.modal.alert().component(this.formModalTpl).open();
    }

    onAction(action: SipConfigTableAction) {
        switch (action.name) {
            case 'edit':
                this.onEdit(action.data);
                break;
        }
    }

    onAfterSave() {
        this.formModal.close();
        this.table.update();
    }

    batchClear(): void {
        this.progressShow = false;
        this.selectedRows = [];
        this.table.resetBatch();
    }

    onBatchDelete() {
        this.modal.confirm().open().result.then(res => {
            if (!res) {return;}
            this.batchDelete();
        });
    }

    private batchDelete() {
        const tasks = this.selectedRows.map(row => {
            return this.service.deleteConfig(row.id).pipe(map(() => {
                this.notificationService.success('Config deleted', 'SIP');
                row.batch = false;
                this.completeTask()
            }),catchError((err, caught) => {
                this.notificationService.error('Config delete an error', 'SIP');
                this.completeTask();
                return caught;
            }));
        });
        this.startTasks(tasks);
    }

    onChangeInputSearch(phrase) {
        if (phrase instanceof Event) {
            return;
        }
        this.table.gridApi.setGridOption('quickFilterText', phrase.trim());
    }

    startTasks(tasks: Observable<any>[]) {
        this.progressShow = true;
        this.batchCompleted = 0;
        this.table.loading = true;
        (new BatchTasks(tasks).run(4));
    }

    completeTask() {
        this.batchCompleted++;
        if (this.selectedRows.length === this.batchCompleted) {
            this.table.loading = false;
            this.progressShow = false;
            this.selectedRows = [];
            this.table.update();
        }
    }

    ngOnDestroy() {
        this.searchSubscription.unsubscribe();
    }
}
