
import {catchError, map} from 'rxjs/operators';
import { Component, TemplateRef, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NotificationService } from '../../../shared/services/notification.service';
import { AppscriptsPaymentRequestService } from '../../../shared/services/appscripts-payment-request.service';
import { AppscriptsPaymentRequestTableComponent } from '../appscripts-payment-request-table/appscripts-payment-request-table.component';
import { RowDef } from '../../../shared/components/table/table.component';
import { Observable } from 'rxjs';
import { BatchTasks } from '../../../shared/services/batch-tasks';
import {AppscriptsPaymentRequestFilter} from "../../../shared/models/appscripts-payment-request.model";
import {DialogRef, ModalService} from "../../../shared/services/modal.service";
import { BrowserUtils } from '../../../shared/services/browser-utils';
import { AppscriptsPaymentRequestFormComponent } from "../appscripts-payment-request-form/appscripts-payment-request-form.component";

@Component({
    selector: 'app-appscripts-payment-request-index',
    templateUrl: 'appscripts-payment-request-index.component.html',
    styleUrls: []
})

export class AppscriptsPaymentRequestIndexComponent {

    @ViewChild('copyPhonesModalTpl', {read: TemplateRef, static: false}) copyPhonesModalTpl: any;
    @ViewChild('paymentRequestUpdateModalTpl', { read: TemplateRef, static: false }) paymentRequestUpdateModalTpl: any;
    @ViewChild(AppscriptsPaymentRequestTableComponent, {static: false}) table: AppscriptsPaymentRequestTableComponent;

    @ViewChild(AppscriptsPaymentRequestFormComponent, {static: false}) form: AppscriptsPaymentRequestFormComponent;

    copyPhonesText: string;
    copyPhonesTextControlRows: number;
    paymentRequestUpdateModal: DialogRef;

    selectedRows: RowDef[] = [];
    batchCompleted = 0;
    progressShow = false;

    model: any;

    constructor(
        public router: Router,
        public modemService: AppscriptsPaymentRequestService,
        public notificationService: NotificationService,
        titleService: Title,
        public modal: ModalService
    ) {
        titleService.setTitle('Payment requests');
    }

    action(event) {
        if (event.name === 'edit') {
            this.model = event.row.data;
            this.paymentRequestUpdateModal= this.modal.alert().component(this.paymentRequestUpdateModalTpl).open();
            this.paymentRequestUpdateModal.onDestroy.subscribe(() => {
                this.table.flashRow(event.row.data.id);
            });
        }
    }

    onAfterSavePaymentRequest() {
        this.paymentRequestUpdateModal.close(true);
        this.table.update();
    }

    onFilter(data) {
        let params: AppscriptsPaymentRequestFilter = {};
        if (data.createdAtFrom && data.createdAtTo) {
            params.createdAtFrom = data.createdAtFrom;
            params.createdAtTo = data.createdAtTo;
        }

        let isInt = (v) => !(v === '' || v === null);

        if (isInt(data.amountMin) && isInt(data.amountMax)) {
            params.amountMin = Math.trunc(data.amountMin * 100);
            params.amountMax = Math.trunc(data.amountMax * 100);
        } else if (isInt(data.amountMin)) {
            params.amountMin = Math.trunc(data.amountMin * 100);
        } else if (isInt(data.amountMax)) {
            params.amountMax = Math.trunc(data.amountMax * 100);
        }

        let rawSearchFields = ['phoneNumbers', 'email', 'paymentMethodName', 'paymentAddress'];
        rawSearchFields.map(field => {
            let v = data[field];
            if (v === '' || v === null) {
                return;
            }
            params[field] = v;
        });
        if (params['phoneNumbers']) {
            params['phoneNumbers'] = params['phoneNumbers'].trim().split(' ').map(_ => _.trim()).filter(_ => _).join(',');
        }
        params.statuses = data.statuses;
        params.withdrawalAvailable = data.withdrawalAvailable;

        this.table.filter = params;
        this.table.page = 1;
        this.table.update();
    }

    onBatchDelete() {
        this.modal.confirm().open().result.then(res => {
            if (!res) {return;}
            this.batchDelete();
        });
    }

    private batchDelete() {
        const tasks = this.selectedRows.map(row => {
            return this.modemService.delete(row.data.id).pipe(
                map(() => {
                    this.notificationService.success('Payment requests deleted', 'Appscripts');
                    row.batch = false;
                    this.completeTask();
                }),
                catchError((err, caught) => {
                    this.notificationService.error('Payment request delete an error', 'Appscripts');
                    this.completeTask();
                    return caught;
                })
            );
        });
        this.startTasks(tasks);
    }

    onBatchMarkAsPaid() {
        this.modal.confirm().open().result.then(res => {
            if (!res) {return;}
            this.batchMarkAsPaid();
        });
    }

    private batchMarkAsPaid() {
        const tasks = this.selectedRows.map(row => {
            return this.modemService.updateStatus(row.data.id, 'PAID').pipe(map(() => {
                this.notificationService.success('Payment requests updated', 'Appscripts');
                row.batch = false;
                this.completeTask();
            }),catchError((err, caught) => {
                this.notificationService.error('Payment request updating an error', 'Appscripts');
                this.completeTask();
                return caught;
            }));
        });
        this.startTasks(tasks);
    }

    onBatchMarkAsFailed() {
        this.modal.confirm().open().result.then(res => {
            if (!res) {return;}
            this.batchMarkAsFailed();
        });
    }

    private batchMarkAsFailed() {
        const tasks = this.selectedRows.map(row => {
            return this.modemService.updateStatus(row.data.id, 'FAILED').pipe(map(() => {
                this.notificationService.success('Payment requests updated', 'Appscripts');
                row.batch = false;
                this.completeTask();
            }),catchError((err, caught) => {
                this.notificationService.error('Payment request updating an error', 'Appscripts');
                this.completeTask();
                return caught;
            }));
        });
        this.startTasks(tasks);
    }

    onClickCopyEmails() {
        let emails = this.selectedRows.map(_ => _.data.email);
        BrowserUtils.copyToClipboard([...new Set(emails)].join("\n"));
        this.notificationService.success('Copy to clipboard', 'Appscripts');
    }

    onClickCopyPhones() {
        let phones = [];
        this.selectedRows.forEach(row => {
            row.data.phoneNumbers.forEach(p => phones.push(p));
        });
        BrowserUtils.copyToClipboard([...new Set(phones)].join("\n"));
        this.notificationService.success('Copy to clipboard', 'Appscripts');
    }

    onClickCopyPhonesControl(e) {
        let input = e.target;
        if (typeof input.setSelectionRange !== 'undefined') {
            input.setSelectionRange(0, input.value.length)
        } else {
            input.select();
        }
    }

    startTasks(tasks: Observable<any>[]) {
        this.progressShow = true;
        this.batchCompleted = 0;
        this.table.loading = true;
        (new BatchTasks(tasks).run(4));
    }

    completeTask() {
        this.batchCompleted++;
        if (this.selectedRows.length === this.batchCompleted) {
            this.table.loading = false;
            this.progressShow = false;
            this.selectedRows = [];
            this.table.update();
        }
    }

    batchClear() {
        this.progressShow = false;
        this.selectedRows = [];
        this.table.resetBatch();
    }
}