
export interface SipConfig {
    id: number;
    enabled: boolean;
    createdAt: Date|string;
    updatedAt: Date|string|null;
    name: string;
    host: string;
    port: number;
    protocol: string;
    prefix: string|null;
    userId: number;
    mainaccountId: number;
    online: boolean;
    batch: boolean;
}

export interface SipConfigUpdate {
    id: number;
    enabled: boolean;
    name: string;
    host: string;
    port: number;
    protocol: string;
    prefix: string|null;
}

export function sipConfigToUpdate(config: SipConfig): SipConfigUpdate {
    return {
        id: config.id,
        enabled: config.enabled,
        name: config.name,
        host: config.host,
        port: config.port,
        protocol: config.protocol,
        prefix: config.prefix
    }
}