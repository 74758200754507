import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorageService } from "./localStorage.service";
import { Setting } from "../models/setting.model";
import { SettingType } from "../models/setting.model";
import { RestUtils } from './rest-utils';
import { delay, map } from "rxjs/operators";
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { Role } from "../models/user.model";

@Injectable()
export class SettingService {

    http: HttpClient;

    utils = new RestUtils();

    headers = new HttpHeaders();

    storage: LocalStorageService;

    private isSidebarOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    constructor(http: HttpClient, storage: LocalStorageService) {
        this.http = http;
        this.storage = storage;
        this.headers = this.headers.set('Content-Type', 'application/json');
    }

    getSidebarState(): Observable<boolean> {
        return this.isSidebarOpen.asObservable();
    }

    setSidebarState(isOpen: boolean) {
        this.isSidebarOpen.next(isOpen);
    }

    all() {
        let url = this.utils.buildUrl('ROLE/settings', {});
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.get<Setting[]>(url, options);
    }

    allKv() {
        return this.all().pipe(map(settings => {
            let settingsMap = {};
            settings.map(s => settingsMap[s.name] = this.toValue(s));
            return settingsMap;
        }));
    }

    save(setting: Setting) {
        let url = this.utils.buildUrl('ROLE/settings', {});
        let options = this.utils.getHttpHeaderOptions(this.headers);

        return this.http.put(url, setting, options).pipe(delay(10000));
    }

    get(name) {
        let settings = this.storage.get('settings');
        return typeof settings[name] !== undefined ? settings[name] : null;
    }

    toValue(setting: Setting): number | boolean | string {
        if (setting.valType === SettingType.INT) {
            return parseInt(setting.valRaw)
        }
        if (setting.valType === SettingType.BOOL) {
            return setting.valRaw === 'true'
        }

        return setting.valRaw
    }

    toString(val: number | boolean | string, type: string): string {
        if (type === SettingType.BOOL) {
            return val ? 'true' : 'false';
        }

        return String(val);
    }

    static getServiceState(serviceName, settings, role, defaultMessage = null) {
        const key = serviceName + '_disabled';
        const keyForClient = serviceName + '_disabled_for_client';
        const keyMessage = serviceName + '_disabled_message';

        const message = typeof settings[keyMessage] !== "undefined" ? settings[keyMessage] : defaultMessage;
        const disabled = typeof settings[key] !== "undefined" && settings[key];
        const disabledForClient = typeof settings[keyForClient] !== "undefined" && settings[keyForClient];

        if (disabled || (role !== Role.ADMIN && disabledForClient)) {
            return { disabled: true, message: message };
        }

        return { disabled: false, message: message };
    }
}