import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TableBase } from '../../shared/components/table/table-base';
import { ActionEvent, CellClickEvent, ColumnTypes, SortEvent } from '../../shared/components/table/table.component';
import { MoShortCodeCollection } from '../../shared/models/mo-shortcode.model';
import { AllRequestParams, MoShortcodeService } from '../../shared/services/mo-shortcode.service';
import { DialogRef, ModalService } from '../../shared/services/modal.service';
import { NotificationService } from '../../shared/services/notification.service';
import { UsersService } from "../../shared/services/users.service";
import { Role } from "../../shared/models/user.model";

@Component({
    selector: 'app-mo-shortcode-table',
    templateUrl: './mo-shortcode-table.component.html',
    styleUrls: ['./mo-shortcode-table.component.scss']
})
export class MoShortcodeTableComponent extends TableBase implements OnInit {

    @ViewChild('shortCodeCreateEditModalTpl', { read: TemplateRef, static: false }) shortCodeCreateEditModalTpl: any;
    @ViewChild('deleteModalTpl', { read: TemplateRef, static: false }) deleteModalTpl: any;
    dialogRef: DialogRef;
    @Output() onSearch: EventEmitter<string> = new EventEmitter();

    isAdmin: boolean = false;
    onAfterDelete = new EventEmitter();
    messageForModal: string;
    storageContextName = 'mo-shortcodes-table';

    requestParams = new AllRequestParams();

    constructor(
        public notificationService: NotificationService,
        public moShortCodeService: MoShortcodeService,
        public router: Router,
        public modal: ModalService,
        private userService: UsersService
    ) {
        super();
    }

    ngOnInit() {
        this.userService.getAuthUser().then(u => {
            this.isAdmin = u.role === Role.ADMIN;
            this.init();
        });
    }

    private init() {
        let columns = [
            { title: 'ID', prop: 'id', sort: true },
            { title: 'Short code', prop: 'phoneNumber', sort: true },
            { title: 'Country', prop: 'country', sort: true },
            { title: 'Enabled', prop: 'enabled', type: ColumnTypes.INPUT_SWITCH, cellClassName: 'text-center' },
            { title: 'Description', prop: 'description' },
            {
                title: 'User', prop: 'username', format: (row) => `${row.user.email}`
            },
        ];
        if (!this.isAdmin) {
            columns = columns.filter(_ => _.prop !== 'username');
        }
        this.setColumns(columns);

        this.tableActions = [
            { icon: 'icon-edit', name: 'edit', title: 'Edit' },
            { icon: 'far fa-trash-alt', name: 'delete', title: 'Delete' },
        ];

        if (this.order.length === 0) {
            this.columns[0].sortDirection = 'desc';
            this.order.push({ prop: 'id', direction: 'desc' });
        }
        this.update();
    }

    onPageChange(event) {
        this.page = event;
        this.update();
    }

    onAction(event: ActionEvent) {
        if (event.name === 'edit') {
            this.router.navigate(
                ['/mo-testing/short-codes'],
                { queryParams: { id: event.row.data.id } }
            );
            this.openShortCodeCreateEditModal();
        }
        if (event.name === 'delete') {
            this.messageForModal = `You are going to delete phone number "${event.row.data.phoneNumber}". You won't be able to revert this!`;
            let dialogRef = this.modal.alert().dialogClass('modal-dialog small-modal').component(this.deleteModalTpl).open();
            dialogRef.result.then(result => {
                if (result) {
                    this.delete(event.row.data.id);
                }
            });
        }
        if (event.name === 'switch' && event.column.prop === 'enabled') {
            this.moShortCodeService.updateMoShortCode(event.row.data.id, event.row.data).subscribe({
                next: (res) => {
                    this.notificationService.success('MO short code updated.', 'Mobile Originated');
                    this.update();
                },
                error: (err) => {
                    this.notificationService.error({
                        title: 'Mobile Originated',
                        message: 'An error occurred while updating MO short code.',
                        serviceName: 'MO',
                        requestMessage: err.statusText,
                        requestCode: err.status,
                        ts: err.timestamp ? err.timestamp : null
                    });
                    this.update();
                }
            });
        }
    }

    onCellClick(event: CellClickEvent) {

    }

    changeSize($event, size) {
        super.onChangeSize(size);
        this.update();
    }

    update() {
        this.loading = true;
        this.requestParams.size = this.currentSize;
        this.requestParams.page = this.page - 1;
        this.requestParams.resetSort();

        for (let sort of this.order) {
            this.requestParams.setSort(sort.prop, sort.direction);
        }

        this.moShortCodeService.all(this.requestParams).subscribe({
            next: (res: MoShortCodeCollection) => {
                this.setData(this.createRows(res.content), res.totalElements);
                this.loading = false;
            },
            error: e => {
                this.loading = false;
                this.notificationService.error({
                    title: 'Mobile Originated',
                    message: 'Error in loading short codes',
                    serviceName: 'MO',
                    requestMessage: e.statusText,
                    requestCode: e.status
                });
            }
        })
    }

    delete(id: number) {
        this.moShortCodeService.deleteMoShortCode(id).subscribe({
            next: (res) => {
                this.notificationService.success('Phone number deleted', 'Mobile Originated');
                this.resetBatch();
                this.update();
            },
            error: (err) => {
                this.notificationService.error({
                    title: 'Mobile Originated',
                    message: 'An error occurred while deleting this short code.',
                    serviceName: 'MO',
                    requestMessage: err.statusText,
                    requestCode: err.status,
                    ts: err.timestamp ? err.timestamp : null
                });
            }
        });
    }

    onSort(order: SortEvent[]) {
        this.onChangeOrder(order);
        this.order = order;
        this.update();
    }

    openShortCodeCreateEditModal() {
        this.dialogRef = this.modal.alert().dialogClass('modal-dialog small-modal').component(this.shortCodeCreateEditModalTpl).open();
        this.dialogRef.result.then(result => {
            if (result) {
                this.update();
            }
            this.router.navigate(['/mo-testing/short-codes']);
        });
    }

    onAfterSave(data: boolean) {
        this.dialogRef.close(data);
    }

}
