
import { DatePipe } from '@angular/common';
import { Component, EventEmitter, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import {
    CellClickedEvent,
    ColDef,
    ColumnMovedEvent,
    ColumnResizedEvent,
    ColumnVisibleEvent,
    GridReadyEvent,
    ICellRendererParams,
    RowSelectedEvent
} from 'ag-grid-community';
import { catchError, debounceTime, throwError } from 'rxjs';
import {
    SipConfigTableAction,
    SipConfigTableActionsComponent
} from './sip-config-table-actions/sip-config-table-actions.component';
import { AGTableBase } from "../../../shared/components/table/ag-table-base";
import { SipConfig, sipConfigToUpdate } from "../../../shared/models/sip.model";
import { NotificationService } from "../../../shared/services/notification.service";
import { SipService } from "../../../shared/services/sip.service";
import { UsersService } from "../../../shared/services/users.service";
import { ModalService } from "../../../shared/services/modal.service";
import { LocalStorageService } from "../../../shared/services/localStorage.service";

@Component({
    selector: 'app-sip-config-table',
    templateUrl: './sip-config-table.component.html',
})

export class SipConfigTableComponent extends AGTableBase implements OnInit, OnDestroy {

    @Output() actions: EventEmitter<SipConfigTableAction> = new EventEmitter();
    @Output() onAfterDelete: EventEmitter<void> = new EventEmitter();
    @Output() changeBatch = new EventEmitter<SipConfig[]>();

    @ViewChild('deleteModalTpl', { read: TemplateRef, static: false }) deleteModalTpl: any;

    loading: boolean = true;
    batchSelectedIds: Set<number> = new Set();

    // ng grid
    columnDefs: ColDef<SipConfig>[] = [
        {
            headerName: '', field: 'batch', checkboxSelection: true,
            maxWidth: 40, headerCheckboxSelection: true, pinned: 'left',
            lockPinned: true, lockPosition: 'left', lockVisible: true,
            suppressColumnsToolPanel: true, suppressMenu: true, suppressMovable: true, cellClass: 'batch-cell',
        },
        {
            headerName: 'ID', field: 'id',
            maxWidth: 80, minWidth: 80,
            sortable: false, initialSort: null, sortingOrder: ['desc', 'asc', null], lockVisible: true,
            cellRenderer: (params: ICellRendererParams<SipConfig>) => {
                const _ = params.data;
                return `<div class="one-line">${_.id}</div>`;
            }
        },
        {
            headerName: 'Created', field: 'createdAt',
            sortable: false, comparator: () => 0, initialSort: null, sortingOrder: ['desc', 'asc', null],
            cellRenderer: (params: ICellRendererParams<SipConfig>) => {
                const _ = params.data;
                return `<div class="one-line">${this.formatDate(_.createdAt)}</div>`;
            }
        },
        {
            headerName: 'Updated', field: 'updatedAt',
            sortable: false, comparator: () => 0, initialSort: null, sortingOrder: ['desc', 'asc', null], initialHide: true,
            cellRenderer: (params: ICellRendererParams<SipConfig>) => {
                const _ = params.data;
                return `<div class="one-line">${_.updatedAt ? this.formatDate(_.updatedAt): ''}</div>`;
            }
        },
        {
            headerName: 'Name', field: 'name',
            sortable: false, comparator: () => 0, initialSort: null, sortingOrder: ['desc', 'asc', null],
            cellRenderer: (params: ICellRendererParams<SipConfig>) => {
                const _ = params.data;
                return `<div class="one-line">${_.name}</div>`;
            }
        },
        {
            headerName: 'Protocol', field: 'protocol',
            sortable: false, comparator: () => 0, initialSort: null, sortingOrder: ['desc', 'asc', null],
            cellRenderer: (params: ICellRendererParams<SipConfig>) => {
                const _ = params.data;
                return `<div class="one-line">${_.protocol}</div>`;
            }
        },
        {
            headerName: 'Host', field: 'host',
            sortable: false, comparator: () => 0, initialSort: null, sortingOrder: ['desc', 'asc', null],
            cellRenderer: (params: ICellRendererParams<SipConfig>) => {
                const isOnline = params.data.online;
                return `<div class="one-line d-flex align-items-center gap-0_5" title="${isOnline ? 'Connected' : 'No Connection'}" >
                    ${params.data.host}:${params.data.port} <span class="fas fa-circle ${isOnline ? 'text-sucess-s2' : 'text-error-r2'}"></span>
                </div>`;
            }
        },
        {
            headerName: 'Prefix', field: 'prefix',
            sortable: false, comparator: () => 0, initialSort: null, sortingOrder: ['desc', 'asc', null], initialHide: true,
            cellRenderer: (params: ICellRendererParams<SipConfig>) => {
                const _ = params.data;
                return `<div class="one-line">${_.prefix ? _.prefix : 'N/A'}</div>`;
            }
        },
        {
            headerName: 'Enabled', field: 'enabled',
            minWidth: 110, maxWidth: 110,
            sortable: false, comparator: () => 0, initialSort: null, sortingOrder: ['desc', 'asc', null],
            cellClass: 'justify-content-center',
            cellRenderer: (params: ICellRendererParams<SipConfig>) => {
                const isChecked = params.data.enabled ? 'checked' : '';
                return `<div class="form-check form-switch d-flex justify-content-center">
                            <input type="checkbox" class="form-check-input medium" ${isChecked}/>
                        </div>`;
            },
            onCellClicked: (event: CellClickedEvent<SipConfig>) => this.onClickEnabled(event.data),
        },
        {
            headerName: 'Actions', field: 'id', maxWidth: 100, minWidth: 100,
            pinned: 'right', lockPinned: true, lockPosition: 'right', lockVisible: true,
            suppressColumnsToolPanel: false, suppressMenu: false, suppressAutoSize: true,
            headerTooltip: 'Right click on a row to see more options.',
            headerClass: 'action-cell',
            cellClass: 'action-cell',
            cellRenderer: SipConfigTableActionsComponent,
            cellRendererParams: {
                onAction: (action: SipConfigTableAction) => this.onAction(action)
            },
        }
    ];

    rowData!: SipConfig[];
    // ng grid

    constructor(
        public notificationService: NotificationService,
        public service: SipService,
        public userService: UsersService,
        public modal: ModalService,
        private datePipe: DatePipe,
        private localStorage: LocalStorageService
    ) {
        super();
    }

    ngOnInit() {
        this.loading = true;
        this.columnChange$.pipe(
            debounceTime(1000)
        ).subscribe((event: ColumnMovedEvent | ColumnResizedEvent | ColumnVisibleEvent) => {
            const currentColumnState = this.gridApi.getColumnState();
            this.localStorage.set(`sip_config_table_state_${this.userService.authUser.id}`, currentColumnState);
        });
    }

    onAction(action: SipConfigTableAction) {
        if (action.name === 'delete') {
            let dialogRef = this.modal.alert().size('sm').component(this.deleteModalTpl).open();
            dialogRef.result.then(result => {
                if (result) {
                    this.delete(action.data);
                }
            });
            return;
        }
        this.actions.emit(action);
    }

    update() {
        this.loading = true;
        this.service.allConfig().pipe(
            catchError(e => {
                this.loading = false;
                this.notificationService.error({
                    title: 'SIP config',
                    message: 'An error occurred while loading the configuration',
                    serviceName: 'SIP',
                    requestMessage: e.statusText,
                    requestCode: e.status
                });
                return throwError(() => e);
            })
        ).subscribe(data => {
            this.rowData = data;
            this.totalRowsCount = data.length;
            this.loading = false;
        });
    }

    delete(report: SipConfig) {
        this.loading = true;
        this.service.deleteConfig(report.id).pipe(
            catchError(e => {
                this.loading = false;
                this.notificationService.error({
                    title: 'SIP config',
                    message: 'An error occurred while deleting the config',
                    serviceName: 'SIP',
                    requestMessage: e.statusText,
                    requestCode: e.status
                });
                return throwError(() => e);
            })
        ).subscribe(() => {
            this.notificationService.success('Config removed', 'SIP config');
            this.update();
        });
    }

    onClickEnabled(config: SipConfig) {
        this.loading = true;
        config.enabled = !config.enabled;
        this.service.saveConfig(sipConfigToUpdate(config)).pipe(
            catchError(e => {
                this.loading = false;
                this.notificationService.error({
                    title: 'SIP config',
                    message: 'An error occurred while saving the config',
                    serviceName: 'SIP',
                    requestMessage: e.statusText,
                    requestCode: e.status
                });
                config.enabled = !config.enabled;
                return throwError(() => e);
            })
        ).subscribe(() => {
            this.loading = false;
            this.notificationService.success('Config saved', 'SIP config');
        });
    }

    formatDate(date) {
        return this.datePipe.transform(date, 'dd MMM HH:mm:ss');
    }

    resetBatch() {
        this.batchSelectedIds.clear();
        this.gridApi.deselectAll();
    }

    // ng grid ...
    onGridReady(params: GridReadyEvent) {
        this.gridApi = params.api;
        this.update();
        const columnState = this.localStorage.get(`sip_config_table_state_${this.userService.authUser.id}`);
        this.gridApi.applyColumnState({ state: columnState, applyOrder: true });
    }

    onRowSelected(event: RowSelectedEvent) {
        const selectedRows = this.gridApi.getSelectedRows();
        this.batchSelectedIds = new Set(selectedRows.map(row => row.id));
        this.changeBatch.emit(selectedRows);
    }
    // ng grid ...

    ngOnDestroy(): void {

    }
}