import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { MoTestingResult } from '../../../../shared/models/mo-testing.model';
import { MoTestingService } from '../../../../shared/services/mo-testing.service';
import { UsersService } from '../../../../shared/services/users.service';
import { Role } from "../../../../shared/models/user.model";

@Component({
    selector: 'app-mo-results-table-actions',
    templateUrl: './mo-results-table-actions.component.html',
    styleUrls: ['./mo-results-table-actions.component.scss']
})
export class MoResultsTableActionsComponent {

    currentRow: MoTestingResult;
    showModem: boolean = true;

    constructor(private userService: UsersService, private moTestingService: MoTestingService) { }

    agInit(params: ICellRendererParams): void {
        this.currentRow = params.data;
        this.showModem = this.userService.authUser.role === Role.ADMIN;
    }

    refresh(params: ICellRendererParams) {
        return true;
    }

    onClick(clickedItem: string) {
        this.moTestingService.announceAction({ name: clickedItem, row: this.currentRow, column: 'action' });
    }

}
