
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, ElementRef, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from "@angular/forms";
import { InputSearchDatePeriod } from "../../../shared/components/input/input.search.datePeriod";
import { Filter } from '../../../shared/filter/filter';
import { MANUAL_TEST_STATUSES } from '../../../shared/models/test-group.model';
import { AuthUser, BasicUser, Role } from '../../../shared/models/user.model';
import { CustomUtils } from '../../../shared/services/custom-utils';
import { ExportService } from "../../../shared/services/export.service";
import { LocalStorageService } from "../../../shared/services/localStorage.service";
import { MntFilterModel, SearchParams } from '../../../shared/services/manual-number-testing.service';
import { DialogRef, ModalService } from "../../../shared/services/modal.service";
import { NotificationService } from "../../../shared/services/notification.service";
import { UsersService } from '../../../shared/services/users.service';
import { ValidationService, Validators as Vld } from '../../../shared/services/validation.service';
import { UsersSelectComponent } from '../../../users/users-select/users-select.component';
import { DestinationsSelectComponent } from '../../destinations-select/destinations-select.component';

@Component({
    selector: 'app-mnt-search-form',
    templateUrl: 'mnt-search-form.component.html',
    styleUrls: ['mnt-search-form.component.scss']
})

export class MntSearchFormComponent implements OnInit {

    @Output() onSubmit = new EventEmitter;

    @ViewChild(DestinationsSelectComponent, { static: false }) countries: DestinationsSelectComponent;
    @ViewChild(UsersSelectComponent, { static: false }) users: UsersSelectComponent;
    @ViewChild(InputSearchDatePeriod, { static: false }) datePeriod: InputSearchDatePeriod;

    @ViewChild('filterTopBar', { static: false }) filterTopBar: ElementRef;
    @ViewChild('usersModalTpl', { read: TemplateRef, static: false }) usersModalTpl: any;

    usersCollection: BasicUser[] = [];
    showUsers = false;
    onlyMyTests = false;
    saveUserIds = true;
    userId: number;
    isBeingDragged: boolean = false;

    @ViewChild('exportModalTpl', { read: TemplateRef, static: false }) exportModalTpl: any;
    exportSize: string | number = 'Custom';
    customExportSize = 1000;
    exportSizes = ['Custom', 'All'];
    exportData = {
        url: undefined,
        size: undefined,
        sizeKb: undefined
    };
    exportSpinner = false;

    form: FormGroup;

    receiptStatuses = [];

    filters: Filter[] = [
        { filterKey: 'searchById', title: 'ID', show: true },
        { filterKey: 'destinations', title: 'Destinations', show: true },
        { filterKey: 'users', title: 'Users', show: false },
        { filterKey: 'statuses', title: 'Status', show: true },
        { filterKey: 'searchByTestIdText', title: 'Test ID Text', show: false },
        { filterKey: 'date', title: 'Date', show: false },
        { filterKey: 'searchByPhoneNumbers', title: 'Phone', show: false },
        { filterKey: 'testGroupIds', title: 'Test Group ID', show: false },
        { filterKey: 'channels', title: 'Delivered as (channel)', show: false }
    ];

    model: MntFilterModel = {
        ids: '',
        telqIds: '',
        testGroupIds: '',
        from: null,
        to: null,
        phoneNumbers: '',
        channels: []
    };

    loading = false;
    user: AuthUser;
    usersModal: DialogRef;

    constructor(
        public userService: UsersService,
        public validationService: ValidationService,
        public localStorage: LocalStorageService,
        public modal: ModalService,
        public exportService: ExportService,
        public notificationService: NotificationService,
        formBuilder: FormBuilder
    ) {
        MANUAL_TEST_STATUSES.forEach(_ => this.receiptStatuses.push(_));
        this.form = formBuilder.group({
            ids: ['', Vld.compose([Vld.digitsAndSpaces(true), Vld.maxLength(255)])],
            telqIds: ['', Vld.compose([Vld.maxLength(255)])],
            testGroupIds: ['', Vld.compose([Vld.digitsAndSpaces(true), Vld.maxLength(255)])],
            phoneNumbers: ['', Vld.compose([Vld.maxLength(255)])],
        });
    }

    ngOnInit() {
        this.loading = true;
        this.userService.getAuthUser().then(user => {
            this.user = user;
            const isAdmin = user.role === Role.ADMIN;
            const isMain = user.role === Role.MAIN;
            const isDeputy = user.role === Role.DEPUTY;
            this.showUsers = (isAdmin || isMain || isDeputy || user.showAllResults);
            const existingFilters = this.localStorage.get(`mnt_${this.user.id}_filter_state`) || [];
            if (existingFilters.length === this.filters.length) {
                this.filters = existingFilters;
            } else {
                existingFilters.forEach(ef => {
                    this.filters.filter(_ => _.filterKey === ef.filterKey).forEach(f => {
                        f.show = ef.show;
                    });
                });
            }
            existingFilters.forEach(ef => {
                this.filters.filter(_ => _.filterKey === ef.filterKey).forEach(f => {
                    f.show = ef.show;
                });
            });
            this.filters = this.filters.filter(f => {
                if (!this.showUsers && f.filterKey === 'users') {
                    return false;
                }
                return true;
            });
            this.update();
        });
    }

    onChangeDate(dates) {
        if (dates instanceof Event || typeof dates === 'string') {
            return;
        }
        if (dates?.startStr && dates?.endStr) {
            this.model.from = dates.startStr + '.000Z';
            this.model.to = dates.endStr + '.999Z';
            this.onSubmitClick();
        } else {
            this.model.from = null;
            this.model.to = null;
        }
    }

    shouldAddFilterBeShown(): boolean {
        const hiddenFilters = this.filters.filter(_ => _.show === false);
        return hiddenFilters.length > 0;
    }

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.filters, event.previousIndex, event.currentIndex);
        this.localStorage.set(`mnt_${this.user.id}_filter_state`, this.filters);
    }

    deleteItem(event: CdkDragDrop<string[]>) {
        const indexToHide = event.previousIndex;
        this.filters[indexToHide].show = false;
        this.onClear(this.filters[indexToHide].filterKey);
        this.localStorage.set(`mnt_${this.user.id}_filter_state`, this.filters);
    }

    update() {
        this.loading = true;
        const savedUserSearchDetails = this.localStorage.get(`mnt_user_search_${this.user.id}_search`);
        if (savedUserSearchDetails) {
            this.saveUserIds = true;
            this.populateSavedDetails(savedUserSearchDetails);
        } else {
            this.model.telqIds = '';
            this.model.from = '';
            this.model.to = '';
            this.receiptStatuses.map(_ => _.selected = false);
            this.usersCollection = [];
            if (this.datePeriod) {
                this.datePeriod.model = '';
                this.datePeriod.control.patchValue('');
            }
            // this.saveUserIds = false;
        }
        this.countries.update(savedUserSearchDetails).subscribe(_ => {
            this.loading = false;
        });
    }

    populateSavedDetails(savedModel: SearchParams): void {
        this.model.telqIds = this.joinArrayToStr(savedModel.telqIds, ' ');
        this.model.ids = this.joinArrayToStr(savedModel.ids, ' ');
        this.model.testGroupIds = this.joinArrayToStr(savedModel.testGroupIds, ' ');
        this.model.from = savedModel.createdAtFrom;
        this.model.to = savedModel.createdAtTo;
        this.model.phoneNumbers = savedModel.destinations.filter(_ => _.manualNumber).map(_ => _.phone).join('\n');
        this.model.channels = savedModel.channels ?? [];
        this.usersCollection = savedModel.usersCollection;
        // Check if Show only my tests is enabled... This means usersCollection has only one id of current logged in user ...
        if (savedModel.userIds[0] === this.user.id) {
            this.onlyMyTests = true;
        }
        this.receiptStatuses.map(recieptstatus => {
            if (savedModel.testStatuses.includes(recieptstatus.id)) {
                recieptstatus.selected = true;
            }
        });
        setTimeout(() => {
            if (this.datePeriod) {
                this.datePeriod.start = Date.parse(this.model.from) / 1000;
                this.datePeriod.end = Date.parse(this.model.to) / 1000;
                this.datePeriod.updateValue();
            }
        }, 200);
    }

    private joinArrayToStr(src: any, separator: string): string {
        if (!src) {
            return '';
        }
        if (Array.isArray(src)) {
            return src.join(separator);
        }
        if (typeof src === 'string') {
            return src;
        }
        return '';
    }

    getPopulatedModel(): SearchParams {
        let model: SearchParams = {
            destinations: [],
            testStatuses: [],
            ids: [],
            telqIds: [],
            userIds: [],
            testGroupIds: [],
            channels: []
        };
        model.destinations = this.countries ? this.countries.getSelectedModels().map(_ => {
            if (typeof _.custom !== 'undefined' && _.custom) {
                return { phone: _.phonenumber, manualNumber: true };
            }
            return { mcc: _.mcc, mnc: _.mnc, originalMnc: _.originalMnc };
        }) : [];
        if (this.model.phoneNumbers) {
            this.model.phoneNumbers.split('\n').map(_ => {
                const phone = _.trim();
                if (phone.length > 0) {
                    model.destinations.push({ phone: phone, manualNumber: true });
                }
            });
        }
        model.testStatuses = this.receiptStatuses.filter(_ => _.selected).map(_ => _.id);

        if (this.model.ids) {
            model.ids = this.model.ids.split(' ').map(_ => _.trim()).filter(_ => _);
        }
        if (this.model.telqIds) {
            model.telqIds = this.model.telqIds.split(' ').map(_ => _.trim()).filter(_ => _);
        }
        if (this.model.testGroupIds) {
            model.testGroupIds = this.model.testGroupIds.split(' ').map(_ => _.trim()).filter(_ => _);
        }
        if (this.model.from) {
            model.createdAtFrom = this.model.from;
        }
        if (this.model.to) {
            model.createdAtTo = this.model.to;
        }
        model.userIds = this.onlyMyTests ? [this.user.id] : this.usersCollection.map(u => u.id);
        model.channels = this.model.channels;
        return model;
    }

    onSubmitClick(filterKey: string = null) {
        const searchDetails = this.getPopulatedModel();
        searchDetails.usersCollection = this.usersCollection;
        if (this.saveUserIds) {
            this.localStorage.set(`mnt_user_search_${this.user.id}_search`, searchDetails);
        } else {
            this.localStorage.remove(`mnt_user_search_${this.user.id}_search`);
        }
        this.onSubmit.emit(searchDetails);
        this.handleFilterDropdown(filterKey);
    }

    handleFilterDropdown(filterKey: string = null) {
        const closeDropDownForTheseFilters = ['date', 'commentDate', 'searchByTestIdText', 'searchByPhoneNumbers'];
        if (filterKey && closeDropDownForTheseFilters.includes(filterKey)) {
            this.filterTopBar.nativeElement.click();
        }
    }

    openUsersModal() {
        this.usersModal = this.modal.alert().component(this.usersModalTpl).open();
    }

    onSelectUser(user: BasicUser) {
        this.usersCollection.push(user);
        this.onlyMyTests = false;
        this.saveUserSearch();
        this.onSubmitClick();
    }

    onUnSelectUser(user: BasicUser, event: Event = null) {
        if (event) {
            event.stopPropagation();
        }
        this.usersCollection = this.usersCollection.filter(u => user.id !== u.id);
        this.saveUserSearch();
        this.onSubmitClick();
    }

    onChangeOnlyMyTests() {
        this.onlyMyTests = !this.onlyMyTests;
        if (this.onlyMyTests) {
            this.usersCollection = [];
            this.users?.onClearAll();
            this.onSubmitClick();
        } else {
            const user: BasicUser = { userId: this.user.id, ...this.user };
            this.onUnSelectUser(user);
        }
        this.saveUserSearch();
    }

    onChangeSaveUserIds() {
        this.saveUserIds = !this.saveUserIds;
        if (this.saveUserIds) {
            const searchDetails = this.getPopulatedModel();
            searchDetails.usersCollection = this.usersCollection;
            this.localStorage.set(`mnt_user_search_${this.user.id}_search`, searchDetails);
        } else {
            this.localStorage.remove(`mnt_user_search_${this.user.id}_search`);
        }
        this.saveUserSearch();
    }

    saveUserSearch() {
        const ids = this.usersCollection.map(u => u.id);
        if (ids.length && this.saveUserIds) {
            this.localStorage.set('mnt_user_search_' + this.user.id, ids);
        } else {
            this.localStorage.remove('mnt_user_search_' + this.user.id);
        }
    }

    static getSaveUserIds(localStorage: LocalStorageService, user) {
        return localStorage.get('mnt_user_search_' + user.id, []);
    }

    getPeekForSelectedValues(filter): number | string | null {
        switch (filter.filterKey) {
            case 'destinations':
                return this.countries?.selectedCount;
            case 'users':
                return this.usersCollection.length;
            case 'statuses':
                const count = this.receiptStatuses.filter(rs => rs.selected).length;
                return count;
            case 'searchByPhoneNumbers':
                const phoneNumbers = this.model.phoneNumbers.split('\n').filter(_ => _.trim().length > 0);
                return phoneNumbers?.length;
            default:
                return null;
        }
    }

    onClickExport() {
        this.exportSize = 'Custom';
        this.exportSpinner = false;
        this.exportData = {
            url: undefined,
            size: undefined,
            sizeKb: undefined
        };
        this.modal.alert().dialogClass('modal-dialog small-modal').component(this.exportModalTpl).open();
    }

    export(limit) {
        if (limit === 'Custom') limit = this.customExportSize;
        this.exportSpinner = true;
        let params = this.getPopulatedModel();
        const ids = MntSearchFormComponent.getSaveUserIds(this.localStorage, this.user);
        if (!params && ids.length) {
            params = { userIds: ids }
        }
        this.exportService.export('manual', limit === 'All' ? null : limit, params ? params : {}).then(exportData => {
            this.exportData = exportData;
            this.exportSpinner = false;
        }).catch(error => {
            this.notificationService.error({
                title: 'Manual testing',
                message: 'An error occurred while export tests',
                serviceName: 'MTC',
                requestMessage: error.statusText,
                requestCode: error.status,
                ts: error.timestamp ? error.timestamp : null
            });
            this.exportSpinner = false;
        });
    }

    onAdditionalFilterClick(filter: Filter): void {
        filter.show = !filter.show;
        this.localStorage.set(`mnt_${this.user.id}_filter_state`, this.filters);
    }

    onFilterChange(filter): void {
        for (let f of this.filters) {
            if (f.filterKey === filter) {
                f.show = !f.show;
                break;
            }
        }
    }

    checkFilterCondition(filter: Filter): void {
        if (filter.filterKey === 'searchByPhoneNumbers' && this.countries?.selectedCount > 0) {
            this.countries?.reset();
            this.notificationService.info('Destination and phone number filter cannot be used together. Destination filter has been cleared.', 'Destination filter updated')
        }
    }


    onDestinationChange(): void {
        if (this.model.phoneNumbers?.length > 0) {
            this.model.phoneNumbers = '';
            this.notificationService.info('Destination and phone number filter cannot be used together. Phone number filter has been cleared.', 'Phone filter updated');
        }
        this.onSubmitClick();
    }

    receiptStatusChange(event: any, status: any): void {
        event.stopPropagation();
        status.selected = !status.selected;
        this.onSubmitClick();
    }

    onClearAllReceiptStatus(): void {
        this.receiptStatuses.map(_ => _.selected = false);
        this.onSubmitClick();
    }

    resetAll(): void {
        this.model.telqIds = '';
        this.model.ids = '';
        this.model.from = '';
        this.model.to = '';
        this.model.phoneNumbers = '';
        this.model.testGroupIds = '';
        this.model.channels = [];
        if (this.datePeriod) {
            this.datePeriod.model = '';
            this.datePeriod.control.patchValue('');
        }
        this.receiptStatuses.map(_ => _.selected = false);
        this.countries?.reset();
        this.usersCollection = this.onlyMyTests ? this.usersCollection : [];
        this.users?.onClearAll();
        // this.saveUserIds = false;
    }

    onClearAllFilter(): void {
        this.localStorage.set(`mnt_${this.user.id}_filter_state`, this.filters);
        this.resetAll();
        this.onSubmitClick();
    }

    onClear(filterKey: string) {
        switch (filterKey) {
            case 'searchById':
                this.model.ids = '';
                break;
            case 'searchByTestIdText':
                this.model.telqIds = '';
                break;
            case 'searchByPhoneNumbers':
                this.model.phoneNumbers = '';
                break;
            case 'date':
                this.onChangeDate(null);
                if (this.datePeriod) {
                    this.datePeriod.model = '';
                    this.datePeriod.control.patchValue('');
                    this.datePeriod.reset();
                }
                this.handleFilterDropdown('date');
                break;
            case 'users':
                this.usersCollection = [];
                break;
            case 'statuses':
                this.receiptStatuses.forEach(rs => rs.selected = false);
                break;
            case 'testGroupIds':
                this.model.testGroupIds = '';
                break;
            case 'channels':
                this.model.channels = [];
                break;
        }
        this.onSubmitClick();
    }

    isFilterActive(filter: Filter): boolean {
        switch (filter.filterKey) {
            case 'destinations':
                return this.countries?.selectedCount > 0;
            case 'searchById':
                return this.model.ids?.length > 0;
            case 'searchByTestIdText':
                return this.model.telqIds?.length > 0;
            case 'searchByPhoneNumbers':
                return this.model.phoneNumbers?.length > 0;
            case 'date':
                return this.model.from?.length > 0 || this.model.to?.length > 0;
            case 'users':
                return this.usersCollection.length > 0;
            case 'statuses':
                return this.receiptStatuses.some(rs => rs.selected);
            case 'testGroupIds':
                return this.model.testGroupIds?.length > 0;
            case 'channels':
                return this.model.channels.length > 0;
            default:
                return false;
        }
    }

    onChannelChange(channel: string): void {
        if (this.model.channels.includes(channel)) {
            this.model.channels = this.model.channels.filter(c => c !== channel);
        } else {
            this.model.channels.push(channel);
        }
        this.onSubmitClick();
    }

    onClearAllChannel(): void {
        this.model.channels = [];
        this.onSubmitClick();
    }

    getIconForStatus(statusID: string): string {
        return CustomUtils.getIconForStatus(statusID);
    }

}
