import { Component, Input, SimpleChanges, OnChanges, ViewChild, TemplateRef, OnInit } from "@angular/core";
import {
    CellClickedEvent,
    ColumnMovedEvent,
    ColumnResizedEvent, ColumnVisibleEvent, GetMainMenuItemsParams,
    GridApi,
    GridOptions,
    ICellRendererParams, IRowNode, RowNode,
    SortChangedEvent,
} from "ag-grid-community";
import { NotificationService } from "../../shared/services/notification.service";
import 'ag-grid-enterprise';
import { LocalStorageService } from "../../shared/services/localStorage.service";
import { AgGridUtils } from "../../shared/services/ag-grid-utils";
import { DialogRef, ModalService } from "../../shared/services/modal.service";
import { CoverageNetwork, CoverageNetworkRequest } from "../../shared/models/coverage.model";
import { CoverageService } from "../../shared/services/coverage.service";
import { catchError, debounceTime, throwError } from "rxjs";
import { DatePipe } from "@angular/common";
import { CoverageTableActionsRendererComponent } from "./coverage-table-actions-renderer.component";
import { AGTableBase } from "../../shared/components/table/ag-table-base";
import { UsersService } from "../../shared/services/users.service";
import { Role } from "../../shared/models/user.model";


@Component({
    selector: 'app-coverage-table',
    templateUrl: './coverage-table.component.html',
    styleUrls: [],
})

export class CoverageTableComponent extends AGTableBase implements OnInit {

    loading = false;
    @Input() networks: CoverageNetwork[] = [];

    @ViewChild('requestsModalTpl', { read: TemplateRef, static: false }) requestsModalTpl: any;
    requestsModal: DialogRef;
    requestsLoading = false;
    requestsNetwork: CoverageNetwork;
    requests: CoverageNetworkRequest[] = [];

    theme = AgGridUtils.theme;

    gridApi: GridApi<CoverageNetwork>;

    gridOptions: GridOptions<CoverageNetwork> = {
        onGridReady: e => {
            this.gridApi = e.api;
            const columnState = this.storage.get(`analytics-coverage-table-state`) || [];
            if (columnState && columnState.length) {
                this.gridApi.applyColumnState({ state: columnState });
            }
            this.addHorizontalScrollOnTop();
            this.update();
        },
        onSortChanged: (e: SortChangedEvent) => this.columnChange$.next(e),
        onColumnResized: (event: ColumnResizedEvent<CoverageNetwork>) => this.onColumnResized(event),
        onColumnMoved: (event: ColumnMovedEvent<CoverageNetwork>) => this.onColumnMoved(event),
        onColumnVisible: (event: ColumnVisibleEvent<CoverageNetwork>) => this.onColumnVisible(event),
        getMainMenuItems: (params: GetMainMenuItemsParams) => this.getMainMenuItems(params),
        icons: this.customIcons,
        defaultColDef: this.defaultColDef,
        columnDefs: [
            {
                headerName: 'Country(MCC)/Network(MNC)',
                valueGetter: (params) => {
                    return `${params.data.countryName}(${params.data.mcc})/${params.data.providerName}(${params.data.mnc})`;
                },
                cellRenderer: (params) => {
                    const data = params.data as CoverageNetwork;
                    const className = data.online ? 'text-navy' : 'text-danger';
                    return `<span class="fa fa-circle ${className}" style="font-size: 0.7em;margin-top: -1px"></span> ${params.value}`;
                },
                sortable: true,
                lockVisible: true,
                initialWidth: 150,
                getQuickFilterText: params => [`${params.data.mcc}${params.data.mnc}`, `${params.data.countryName}${params.data.providerName}`].join(''),
            },
            {
                headerName: 'Network online/offline since',
                field: 'onlineChangedAt',
                sortable: true,
                hide: true,
                valueFormatter: (params) => {
                    if (!params.data.onlineChangedAt) {
                        return 'N/A';
                    }
                    return this.datePipe.transform(params.data.onlineChangedAt, 'mediumDate')
                },
                getQuickFilterText: params => '',
            },
            {
                headerName: 'Requested by me',
                field: 'hasMyRequest',
                sortable: true,
                cellRenderer: (params: ICellRendererParams<CoverageNetwork>) => params.data.hasMyRequest ? 'Yes' : 'No',
                getQuickFilterText: params => '',
            },
            {
                headerName: 'Total network requests',
                field: 'requests',
                sortable: true,
                cellClass: 'clickable',
                cellRenderer: (params: ICellRendererParams<CoverageNetwork>) => `<span>${params.data.requests}</span>`,
                onCellClicked: (e: CellClickedEvent<CoverageNetwork>) => this.onAction('requests-list', e.node),
                getQuickFilterText: params => '',
            },
            {
                headerName: 'Action',
                maxWidth: 150,
                minWidth: 150,
                sortable: false,
                pinned: 'right',
                lockPinned: true,
                lockPosition: 'right',
                lockVisible: true,
                suppressColumnsToolPanel: false,
                suppressMenu: false,
                suppressAutoSize: true,
                headerClass: 'action-cell',
                cellClass: 'action-cell',
                cellRenderer: CoverageTableActionsRendererComponent,
                cellRendererParams: {
                    action: (action: string, params: ICellRendererParams<CoverageNetwork>) => this.onAction(action, params.node)
                },
                getQuickFilterText: params => '',
            }
        ],
        popupParent: document.body,
        suppressMenuHide: true,
        suppressDragLeaveHidesColumns: true,
        alwaysMultiSort: true,
        rowModelType: 'clientSide',
        tooltipShowDelay: 500,
        autoSizeStrategy: {
            type: 'fitGridWidth'
        }
    }

    isAdmin = false;

    constructor(
        private notificationService: NotificationService,
        private service: CoverageService,
        private userService: UsersService,
        private storage: LocalStorageService,
        private modal: ModalService,
        private datePipe: DatePipe
    ) {
        super();
        this.gridOptions.defaultColDef.maxWidth = undefined;
    }

    ngOnInit() {
        this.userService.getAuthUser().then(u => {
            this.isAdmin = u.role === Role.ADMIN;
        });
        this.columnChange$.pipe(
            debounceTime(1000)
        ).subscribe((event: ColumnMovedEvent | ColumnResizedEvent | ColumnVisibleEvent) => {
            this.storage.set(`analytics-coverage-table-state`, this.gridApi.getColumnState());
        });
    }

    search(phrase: string) {
        this.gridApi.setGridOption('quickFilterText', phrase);
    }

    private onAction(action: string, node: IRowNode<CoverageNetwork>) {
        const network = node.data;
        if (action === 'request-create') {
            this.loading = true;
            this.service.createRequest(network.id).pipe(
                catchError(e => {
                    this.notificationService.error({
                        title: 'Coverage',
                        message: 'An error occurred while create request',
                        requestMessage: e.statusText,
                        requestCode: e.status
                    });
                    this.loading = false;
                    return throwError(() => e);
                })
            ).subscribe(() => {
                this.loading = false;
                this.notificationService.success(`${network.countryName}/${network.providerName} +1`, 'Coverage');
                network.requests = network.requests + 1;
                network.hasMyRequest = true;
                node.updateData(network);
            });
        }
        if (action === 'request-delete') {
            this.loading = true;
            this.service.deleteRequest(network.id).pipe(
                catchError(e => {
                    this.notificationService.error({
                        title: 'Coverage',
                        message: 'An error occurred while delete request',
                        requestMessage: e.statusText,
                        requestCode: e.status
                    });
                    this.loading = false;
                    return throwError(() => e);
                })
            ).subscribe(() => {
                this.loading = false;
                this.notificationService.success(`${network.countryName}/${network.providerName} -1`, 'Coverage');
                network.requests = network.requests - 1;
                network.hasMyRequest = false;
                node.updateData(network);
            });
        }

        if (action === 'requests-list') {
            this.requestsNetwork = network;
            this.requestsLoading = true;
            this.requestsModal = this.modal.alert().component(this.requestsModalTpl).open()
            this.service.requests(network.id).pipe(
                catchError(e => {
                    this.requestsModal.close();
                    this.requestsLoading = false;
                    this.notificationService.error({
                        title: 'Coverage',
                        message: 'An error occurred while loading requests',
                        requestMessage: e.statusText,
                        requestCode: e.status
                    });
                    return throwError(() => e);
                })
            ).subscribe(requests => {
                this.requests = requests;
                this.requestsLoading = false;
            });
        }
    }

    update(): void {
    }
}