import { Component, OnInit } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { SipConfig } from "../../../../shared/models/sip.model";

@Component({
    selector: 'app-sip-config-table-actions',
    templateUrl: './sip-config-table-actions.component.html',
    styleUrls: ['./sip-config-table-actions.component.scss']
})
export class SipConfigTableActionsComponent {

    currentRow: SipConfig;
    onActionFun: (action: SipConfigTableAction) => void;

    agInit(params: SipConfigTableActionsParams): void {
        this.currentRow = params.data;
        this.onActionFun = params.onAction
    }

    refresh(params: ICellRendererParams) {
        return true;
    }

    onAction(action: string) {
        this.onActionFun({name: action, data: this.currentRow});
    }
}

export interface SipConfigTableAction {
    name: string;
    data: SipConfig;
}

export interface SipConfigTableActionsParams extends ICellRendererParams<SipConfig> {
    onAction: (action: SipConfigTableAction) => void;
}